
<p-dialog [draggable]="true" header="Header" [modal]="true" [(visible)]="productVisible" (onHide)="onDialogHide()" [style]="{width: '70vw',height:'110%'}">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <div class="left-border left-border-bg"></div>
            <div class="d-flex-d-column margin-left-5">
                <span class="user-title-text">Orders</span>
                <span class="user-text-regular">Total No. of Event <span class="user-text-bold">(180)</span></span>
            </div>
        </div>
        <div class="">
            <p-button class="save-btn" label="Save" severity="secondary"></p-button>
        </div>
        <div style="position: relative;z-index: 999;">
            <p-button (onClick)="showFilterModal=!showFilterModal" class="filter-btn" icon="pi pi-fw pi-filter"></p-button>
            <div style="position: absolute;right: 0;" class="mt-3">
                <app-filter-modal [visible]="showFilterModal"></app-filter-modal>
            </div>
        </div>
    </ng-template>
</p-dialog>
