import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditLighthouseDbConnectionDto } from '../../models/IAuditLighthouseDbConnectionDto';
import { baseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class LighthouseDashboardService extends baseService {

  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async getLogEntriesRollupAsync(auditProfileId: string) {
    this.apiRoute = `auditlighthouse/${auditProfileId}/logentries/rollup`;
    return this.get(`/${this.apiRoute}`).toPromise();
  }

  async getLogEntriesAsync(auditLighthouseDbConnectionDto: IAuditLighthouseDbConnectionDto) {
    this.apiRoute = 'auditlighthouse/logentries';
    return this.post(`/${this.apiRoute}`, auditLighthouseDbConnectionDto).toPromise();
  }

  async getLogEntriesTimelineAsync(auditProfileId: string) {
    this.apiRoute = `auditlighthouse/${auditProfileId}/logentries/timeline`;
    return this.get(`/${this.apiRoute}`).toPromise();
  }

  async getAuditTablePreviewDataAsync(auditProfileId: string, tableName: string) {
    this.apiRoute = `auditlighthouse/${auditProfileId}/preview/${tableName}`;
    return this.get(`/${this.apiRoute}`).toPromise();
  }
}
