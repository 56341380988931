import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditDto } from '../../models/IAuditDto';
import { baseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class AuditInfrastructureService extends baseService {

  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async createAuditDatabase(auditDto: IAuditDto) {
    this.apiRoute = 'auditinfrastructure';
    return this.post(`/${this.apiRoute}`, auditDto).toPromise();
  }
}
