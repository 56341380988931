// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
  azureInstrumentaionKey: "157aff1a-1404-4756-a695-e131a7eb1752",
  // Dev
  httpBaseUrl: 'https://app-sqlsafekeep-api-dev-eastus2-001.azurewebsites.net/api/v1',
	// LOCAL - Kastrel
	//httpBaseUrl: 'https://localhost:7221/api/v1',
  dummy_JSONs: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
