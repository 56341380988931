
<p-dialog [draggable]="false"
          header="Header"
          [modal]="true"
          [(visible)]="previewDataVisible"
          (onHide)="onDialogHide()"
          [style]="{width: '70vw',height:'110%'}">
  <ng-template *ngIf="!skeleton" pTemplate="header">
    <div class="d-flex align-items-center">
      <div class="left-border left-border-bg"></div>
      <div class="d-flex-d-column margin-left-5">
        <span class="user-title-text">{{tableName}}</span>
        <span class="user-text-regular">Total No. of Event <span class="user-text-bold">({{totalNumberOfEvents}})</span></span>
      </div>
    </div>
    <div class="">
      <!-- TODO: Get style to leave disabled but "coming soon" -->
      <!--<p-button class="save-btn"
                label="Save"
                severity="secondary"></p-button>-->
    </div>
    <div style="position: relative;z-index: 999;">
      <p-button (onClick)="showFilterModal=!showFilterModal"
                class="filter-btn"
                icon="pi pi-fw pi-filter"></p-button>
      <div style="position: absolute;right: 0;" class="mt-3">
        <app-filter-modal [visible]="showFilterModal" [columns]="tableHeaders"></app-filter-modal>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="skeleton">
    <div class="row mb-2 py-2" style="padding-right:1.5rem;">
        <app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1],class:'mt-1'}"></app-skeleton>
  </div>
</ng-container>

  <div *ngIf="!bodyskeleton" class="preview-data-table">
    <p-table styleClass="custom-table"
             [value]="logEntryReferences"
             [(selection)]="selectedLogEntryReference"
             dataKey="code"
             [paginator]="true"
             [scrollable]="true"
             [rows]="8"
             [totalRecords]="totalNumberOfEvents"
             [selectionPageOnly]="true"
             [tableStyle]="{'min-width': '20rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width:100px"
              *ngFor="let header of tableHeaders">{{header}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-logEntryReference>
        <tr>
          <td>
            <div style="display: inline-block;">{{logEntryReference.AuditType}}</div>
            <div style="display: inline-block;"
            [ngClass]="{'event-colorful-boxes': true,
             'purple-box': logEntryReference.AuditType === 'I' || logEntryReference.AuditType === 'Insert', 
             'red-box': logEntryReference.AuditType === 'D' || logEntryReference.AuditType === 'Delete', 
             'orange-box': logEntryReference.AuditType === 'U' || logEntryReference.AuditType === 'Update'}">
          </div></td>
          <td>{{logEntryReference.AuditId}}</td>
          <td>{{logEntryReference.ModifiedBy}}</td>
          <td>{{logEntryReference.ModifiedDate}}</td>
          <td *ngFor="let column of logEntryReference.TableColumns;">{{column.Value}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>



  <div *ngIf="bodyskeleton" class="preview-data-table">
    <p-table styleClass="custom-table"
             [value]="[1,2,3,4,5,6,7,8]"
             dataKey="code"
             [paginator]="true"
             [scrollable]="true"
             [rows]="6"
             [totalRecords]="6"
             [selectionPageOnly]="true"
             [tableStyle]="{'min-width': '20rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th style="min-width:100px"
              *ngFor="let header of [1,2,3,4,5,6,7]"><app-skeleton [skeleton]="{width:'60px',height:'1rem',count:[1]}"></app-skeleton></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr>
          <td>
            <app-skeleton [skeleton]="{width:'60px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'60px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'30px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'100px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'30px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'60px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
          <td>
            <app-skeleton [skeleton]="{width:'60px',height:'1rem',count:[1]}"></app-skeleton>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>


</p-dialog>
`
