import { Component } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css','../../dashboard.component.css']
})
export class DashboardOverviewComponent {
  cities!: { name: string; code: string; }[];

  ngOnInit() {
    this.cities = [
        { name: 'All time', code: 'NY' },
        { name: 'Rome', code: 'RM' },
        { name: 'London', code: 'LDN' },
        { name: 'Istanbul', code: 'IST' },
        { name: 'Paris', code: 'PRS' }
    ];
}
}
