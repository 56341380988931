<section class="graph">
    <div class="row align">
        <div class="col-6 col-md-6 col-sm-6">
            <!-- <div class="left"> -->
                <p class="left-cont">Graph</p>
            <!-- </div> -->
        </div>
    </div>
    <div class="chart">
        <p-chart type="bar" [data]="data" [options]="options"></p-chart>
    </div>
</section>