<div class="m-4">
  <h2 style="font-size: 28px;font-weight: 700;color: #272B30;align-self: flex-start;">Lighthouse Dashboard</h2>
  <p style="color: #6F767E;font-size: 13px;font-weight: 500;align-self: flex-start;">
    <span style="color: #4C94DC;"><b>DB Name:</b> {{databaseName}}</span>
    <span style="color: #026021; float:right; margin-right:3em !important; cursor: pointer; font-size:1.5em;"
          (click)="gotoDataExplorer()"><b>Data Explorer</b></span>
  </p>
  <!--<div class="w-100 bg-white mt-2 rounded rounded-8">
    <app-audit-activity-overview></app-audit-activity-overview>
  </div>-->
  <div class="w-100 mt-2 rounded rounded-8 panel">
    <p-panel [toggleable]="true" style="width: 100%; margin-bottom: 20px;" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
      <ng-template pTemplate="header">
        <!--<section class="overview">
          <div class="justify-content mb-5">
            <div class="d-flex">
              <div class="left-border"></div>
              <p class="title">Audit Activity Overview</p>
            </div>-->
        <!--<div class="right-algin">
          <p-dropdown optionLabel="name"></p-dropdown>
          <p-dropdown optionLabel="name"></p-dropdown>
        </div>-->
        <!--</div>
        </section>-->
      </ng-template>
      <app-audit-activity-overview></app-audit-activity-overview>

    </p-panel>
  </div>
  <div class="w-100 mt-2 rounded rounded-8 panel">
    <p-panel [toggleable]="true" style="width: 100%; margin-bottom: 20px;" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
      <ng-template pTemplate="header">
        <div class="w-100 bg-white br p-4" style="box-sizing: border-box;">
          <div class="d-flex align-items-center justify-content-between mb-4" style="height: 40px;">
            <div class="d-flex flex-row align-items-center justify-content-between w-50" style="height: 90%;">
              <div class="d-flex align-items-center" style="height: 100%;">
                <div class="left-border"></div>
                <span class="title">DB Summary</span>
              </div>
              <span class="p-input-icon-left" style="height: 100%;">
                <i class="pi pi-search"></i>
                <input type="text" placeholder="Search by name of ID" class="search-input w-100" pInputText />
              </span>
            </div>
            <div>
              <button class="table-button" [ngClass]="{'option-btn-active': !isGridShow, 'option-btn':  isGridShow }"
                      (click)="girdDataEmpty()">
                Option one
              </button>
              <button class="table-button option-btn"
                      [ngClass]="{'option-btn-active': isGridShow, 'option-btn': !isGridShow }" (click)="girdData()">
                Option
                two
              </button>
            </div>
            <button class="table-button"><span class="pi pi-filter"></span></button>
          </div>
        </div>
      </ng-template>
      <div class="w-100 bg-white br p-4" style="box-sizing: border-box;">

      <div class="d-flex flex-column" *ngIf="isGridShow">
        <div class="row w-100 mb-2 header-title">
          <div class="col-3">
            <p-checkbox [(ngModel)]="all" [binary]="true" class="header-checkbox"></p-checkbox>
            <span class="mx-4">Table Name</span>
          </div>
          <span class="col"><span class="w-100 px-3">Last Event</span></span>
          <span class="col d-center">Table No. of Events</span>
          <span class="col d-center">Insertions</span>
          <span class="col d-center">Updates</span>
          <span class="col d-center">Deletions</span>
        </div>
        <div class="hr mb-3 mt-2"></div>
        <ng-container *ngIf="!skeleton">
        <div class="row mb-2 py-2 {{record.selected ? 'content-card-active' : ''}}" style="padding-right:1.5rem;"
             *ngFor="let record of data">
          <div class="col-3 d-flex gap-4">
            <div>
              <p-checkbox [(ngModel)]="record.selected" [binary]="true"></p-checkbox>
            </div>
            <div class="d-flex w-100 align-items-center gap-4">
              <span class="content-title">{{record?.tableName}}</span>
              <!--<div class="view-all hand-cursor" *ngIf="record?.tableName == 'Products'" (click)="showProductDialog()">View All ></div>
              <div class="view-all hand-cursor" *ngIf="record?.tableName == 'Users'" (click)="showUserDialog()">View All ></div>-->
              <div class="view-all hand-cursor" (click)="showTableData(record?.tableName)">Quick View</div>
            </div>
          </div>
          <div class="col" style="font-size: 14px;font-weight: 600;color: #6F767E;">{{record.lastEvent}}</div>
          <div class="col d-center" style="font-size: 15px;font-weight: 600;color: #1A1D1F;">
            {{record.totalEvents}}
          </div>
          <div class="col d-center">
            <div style="width: 130px;"
                 class="d-center gap-2 {{record.selected ? 'stats-container-active' : 'stats-container'}}">
              <span style="background-color:#CABDFF" class="d-center stats-icon">
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.74088 10.8335C9.13412 10.8335 11.0742 8.8934 11.0742 6.50016C11.0742 4.10693 9.13412 2.16683 6.74088 2.16683C4.34765 2.16683 2.40755 4.10693 2.40755 6.50016C2.40755 8.8934 4.34765 10.8335 6.74088 10.8335ZM6.74088 11.9168C9.73243 11.9168 12.1576 9.4917 12.1576 6.50016C12.1576 3.50862 9.73243 1.0835 6.74088 1.0835C3.74934 1.0835 1.32422 3.50862 1.32422 6.50016C1.32422 9.4917 3.74934 11.9168 6.74088 11.9168Z"
                        fill="black" />
                  <path d="M7.28223 4.33317C7.28223 4.03402 7.03971 3.7915 6.74056 3.7915C6.44141 3.7915 6.19889 4.03402 6.19889 4.33317L6.19889 5.95817H4.57389C4.27474 5.95817 4.03223 6.20068 4.03223 6.49984C4.03223 6.79899 4.27474 7.0415 4.57389 7.0415H6.19889V8.6665C6.19889 8.96566 6.44141 9.20817 6.74056 9.20817C7.03971 9.20817 7.28223 8.96566 7.28223 8.6665L7.28223 7.0415H8.90723C9.20638 7.0415 9.44889 6.79899 9.44889 6.49984C9.44889 6.20068 9.20638 5.95817 8.90723 5.95817H7.28223L7.28223 4.33317Z"
                        fill="black" />
                </svg>
              </span>
              <span class="stats-count">{{record.insertions}}</span>
            </div>
          </div>
          <div class="col d-center">
            <div style="width: 130px;"
                 class="d-center gap-2 {{record.selected ? 'stats-container-active' : 'stats-container'}}">
              <span style="background-color: #FFBC99;" class="d-center stats-icon">
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.1343 8.12492V4.87492C10.1343 3.37915 8.92172 2.16659 7.42594 2.16659C5.93017 2.16659 4.71761 3.37915 4.71761 4.87492V8.12492C4.71761 9.62069 5.93017 10.8333 7.42594 10.8333C8.92172 10.8333 10.1343 9.62069 10.1343 8.12492ZM7.42594 1.08325C5.33186 1.08325 3.63428 2.78084 3.63428 4.87492V8.12492C3.63428 10.219 5.33186 11.9166 7.42594 11.9166C9.52002 11.9166 11.2176 10.219 11.2176 8.12492V4.87492C11.2176 2.78084 9.52002 1.08325 7.42594 1.08325Z"
                        fill="#1A1D1F" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.42562 3.25C7.72477 3.25 7.96729 3.49251 7.96729 3.79167L7.96728 5.41667C7.96728 5.71582 7.72477 5.95833 7.42562 5.95833C7.12646 5.95833 6.88395 5.71582 6.88395 5.41667L6.88395 3.79167C6.88395 3.49251 7.12646 3.25 7.42562 3.25Z"
                        fill="#1A1D1F" />
                </svg>
              </span>
              <span class="stats-count">{{record.updates}}</span>
            </div>
          </div>
          <div class="col d-center">
            <div style="width: 130px;"
                 class="d-center gap-2 {{record.selected ? 'stats-container-active' : 'stats-container'}}">
              <span style="background-color: #FFBCB2;" class="d-center stats-icon">
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.02751 5.41675C6.32666 5.41675 6.56917 5.65926 6.56917 5.95841V8.66675C6.56917 8.9659 6.32666 9.20841 6.02751 9.20841C5.72835 9.20841 5.48584 8.9659 5.48584 8.66675V5.95841C5.48584 5.65926 5.72835 5.41675 6.02751 5.41675Z"
                        fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.19401 5.41675C8.49316 5.41675 8.73568 5.65926 8.73568 5.95841V8.66675C8.73568 8.9659 8.49316 9.20841 8.19401 9.20841C7.89486 9.20841 7.65234 8.9659 7.65234 8.66675V5.95841C7.65234 5.65926 7.89486 5.41675 8.19401 5.41675Z"
                        fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.02767 1.08325C5.13021 1.08325 4.40267 1.81079 4.40267 2.70825H2.77767H2.236C1.93685 2.70825 1.69434 2.95076 1.69434 3.24992C1.69434 3.54907 1.93685 3.79158 2.236 3.79158H2.77767V10.2916C2.77767 11.189 3.50521 11.9166 4.40267 11.9166H9.81933C10.7168 11.9166 11.4443 11.189 11.4443 10.2916V3.79158H11.986C12.2852 3.79158 12.5277 3.54907 12.5277 3.24992C12.5277 2.95076 12.2852 2.70825 11.986 2.70825H11.4443H9.81933C9.81933 1.81079 9.0918 1.08325 8.19434 1.08325H6.02767ZM8.736 2.70825C8.736 2.4091 8.49349 2.16659 8.19434 2.16659H6.02767C5.72851 2.16659 5.486 2.4091 5.486 2.70825H8.736ZM4.40267 3.79158H3.861V10.2916C3.861 10.5907 4.10351 10.8333 4.40267 10.8333H9.81933C10.1185 10.8333 10.361 10.5907 10.361 10.2916V3.79158H9.81933H4.40267Z"
                        fill="black" />
                </svg>
              </span>
              <span class="stats-count">{{record.deletions}}</span>
            </div>
          </div>
        </div>
        </ng-container>
        
        <ng-container *ngIf="skeleton">
          <div *ngFor="let my of [1,2,3]" class="row mb-2 py-2" style="padding-right:1.5rem;">
            <div class="col-3 d-flex gap-4">
              <div>
              <app-skeleton [skeleton]="{shape:'square', size:'1.5rem', count:[1]}"></app-skeleton>
              </div>
              <div class="d-flex w-100 align-items-center gap-4">
                <span class="content-title"><app-skeleton [skeleton]="{width: '150px', height:'1rem', count:[1]}"></app-skeleton></span>
              </div>
            </div>
            <div class="col" style="font-size: 14px;font-weight: 600;color: #6F767E;">
              <app-skeleton [skeleton]="{width: '150px', height:'1rem', count:[1]}"></app-skeleton></div>
            <div class="col d-center" style="font-size: 15px;font-weight: 600;color: #1A1D1F;">
              <app-skeleton [skeleton]="{width: '50px', height:'1rem', count:[1]}"></app-skeleton>
            </div>
            <div class="col d-center">
              <div style="width: 130px;"
                   class="d-center gap-2">
                <span class="stats-count"><app-skeleton [skeleton]="{width: '140px', height:'1rem', count:[1]}"></app-skeleton></span>
              </div>
            </div>
            <div class="col d-center">
              <div style="width: 130px;"
                   class="d-center gap-2">
                <span class="stats-count"><app-skeleton [skeleton]="{width: '140px', height:'1rem', count:[1]}"></app-skeleton></span>
              </div>
            </div>
            <div class="col d-center">
              <div style="width: 130px;"
                   class="d-center gap-2">
                <span class="stats-count"><app-skeleton [skeleton]="{width: '140px', height:'1rem', count:[1]}"></app-skeleton></span>
              </div>
            </div>
          </div>
          </ng-container>
          

        <div class="w-100 d-center mt-5 gap-4" style="box-sizing: border-box;">
          <span style="color: rgba(111, 118, 126, 0.50);" class="pi pi-arrow-left"></span>
          <p style="padding-top: 14px;align-self: center;text-align: center;color: rgba(111, 118, 126, 0.50);font-size: 15px;font-weight: 600;">
            1/1
          </p>
          <div class="d-center p-1" style="border:1px solid grey;border-radius: 50%;">
            <span class="pi pi-arrow-right"></span>
          </div>
        </div>
      </div>
      <div class="right-top" *ngIf="!isGridShow">
        <img class="mb-4" src="assets/images/svg/undraw_feeling.svg" />

        <h2 class="grey-black">You have not made any audit yet</h2>
        <span class="mb-4 grey-light">Click the button below to get started</span>

        <button class="primary">Create Audit</button>
      </div>
  </div>
  </p-panel>
</div>
</div>

<ng-container *ngIf="isPreviewDataSelected">
  <app-preview-data-modal (valueChange)="close($event)"></app-preview-data-modal>
</ng-container>
