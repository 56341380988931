import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../../base/base.component';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.css']
})
export class FilterModalComponent extends BaseComponent {
  @Input() visible: boolean = false;
  @Input() columns: any = [];

  @Output() dialogCloseEmitter = new EventEmitter<boolean>();
  @Output() applyFilterEventEmitter = new EventEmitter<any>();

  andOr: any;
  addMoreConds: any = [1];
  filterForm!: FormGroup;
  hideAnimated = this.visible;
  //public columnOptions: { name: string; code: string; }[] = [];
  public operations: { name: string; code: string; }[] = [];
  public showMoreFilters: boolean = false;

  columnOptions: any[] = this.columns; // Populate with your options

  //operations: any[]; // Populate with your options
  andOrOptions: any[] = [{ label: 'AND', value: 'AND' }, { label: 'OR', value: 'OR' }];


  constructor(private fb: FormBuilder) {
    super();
  }

  override ngOnInit() {

    this.filterForm = this.fb.group({
      conditions: this.fb.array([])
    });

    this.addCondition(); // Add the first condition by default

    this.andOr = [
      { value: 'Or' },
      { value: 'And' }
    ];

    this.columns.map((x: any) => {
      this.columnOptions.push({
        name: x,
        code: x
      });
    });

    this.operations.push(
      {
        name: 'Equals ==',
        code: '=='
      },
      {
        name: 'Not Equals !=',
        code: '!='
      },
      {
        name: 'Greater Than >',
        code: '>'
      },
      {
        name: 'Less Than <',
        code: '<'
      },
      {
        name: 'Contains',
        code: '%'
      },
      {
        name: 'Does Not Contain',
        code: '!%'
      },
      {
        name: 'Starts With',
        code: 's'
      },
      {
        name: 'Ends With',
        code: 'e'
      },
      {
        name: 'Is Null',
        code: 'null'
      },
      {
        name: 'Is Not Null',
        code: '!null'
      },
      {
        name: 'Is Empty',
        code: 'empty'
      }
    );

    //this.columnOptions = this.columns;
  }
  get conditions(): FormArray {
    return this.filterForm.get('conditions') as FormArray;
  }

  addCondition() {
    const conditionGroup = this.fb.group({
      column: '',
      value: '',
      operation: '',
      conjunction: ''
    });
    this.conditions.push(conditionGroup);
  }

  removeCondition(index: number) {
    this.conditions.removeAt(index);
  }

  getOperationName(index: number): string {
    const operationControl = this.conditions.at(index).get('operation');
    return operationControl ? operationControl.value.name : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']) {
      this.visible = changes['visible'].currentValue;
      if (!this.visible) {
        setTimeout(() => {
          this.hideAnimated = this.visible;
        }, 300)
      } else {
        this.hideAnimated = this.visible;
      }
    }
  }

  applyFilters() {
    const filterData = this.filterForm.value;

    filterData.conditions.map((x: any) => {
      if (x.column === 'AuditId'
        || x.column === 'AuditType'
        || x.column === 'ModifiedBy'
        || x.column === 'ModifiedDate') {
          x.column = `SSK${x.column}`;
        }
    });

    this.applyFilterEventEmitter.emit(filterData);
  }

  excludedColumns: any = [];

  removeFromExcludeColumnList(itemName: string) {
    let _ = this.excludedColumns.filter((x: any) => x.name !== itemName);
    this.excludedColumns = [];
    this.excludedColumns = _;
  }

  addToExcludeColumnList(item: any) {
    this.excludedColumns.push(item?.value);
  }

}
