import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'app-audit-log-modal',
  templateUrl: './audit-log-modal.component.html',
  styleUrls: ['./audit-log-modal.component.css']
})
export class AuditLogModalComponent extends BaseComponent {

  @Input() auditmetricsDetails!: any
  @Output() dialogCloseEmitter = new EventEmitter<boolean>();

  productlistVisible!: boolean;
  loading!: boolean;
  loading1: boolean = true;
  databases!: { name: string; code: string; }[];
  databaseTables!: { name: string; code: string; }[];
  excludedTables: any[] = [];
  selectedDB: string = '';
  fileName: any;
  isfileZone: boolean = false;
  istemplate: boolean = true;
  waitloading: boolean = false;
  isAuditInProgress: boolean = false;

  isSelecteTab: boolean = false
  isConnectionStringValidated: boolean = false;
  isDatabaseSelected: boolean = false;
  isAuditFormComplete: boolean = false;
  connectionString: string = '';
  status: string = 'To begin, validate your connection string.';

  //isStartedAuditProcess: boolean = false;
  //isCompletedAuditProcess: boolean = false;
  //isCreatingAuditDatabase: boolean = false;
  //isCopyingUsersFromSourceDatabaseToAuditDatabase: boolean = false;
  //isCreatingAuditLighthouse: boolean = false;
  //isCopyingUsersFromAuditDatabaseToLighthouseDatabase: boolean = false;
  //isCreatingAuditDatabase_Completed: boolean = false;
  //isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed: boolean = false;
  //isCreatingAuditLighthouse_Completed: boolean = false;
  //isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed: boolean = false;
  //isValidatingConnectionString: boolean = false;

  isViewAuditLog: boolean = false;
  nextTempForUI_View: number = 0; // temp.. bit for view UI Screens
  response!: number;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    this.viewAuditLog();
  }

  viewAuditLog() {
    this.isViewAuditLog = true;
  }

  isGettingDatabases: boolean = false;
  isGettingDatabaseTables: boolean = false;

  onBasicUploadAuto(uploaditem: any) { }

  dialogClose() {
    this.dialogClosedEmitter();
    //this.excludedTables
    //this.excludedTables = [];
  }

  dialogClosedEmitter() {
    this.dialogCloseEmitter.emit(false);
  }

}
