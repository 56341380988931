import { Component } from '@angular/core';
import { AuditProfileService } from '../../services/audit-profile/audit-profile.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent {
  cities!: { name: string; icon: string; }[];
  constructor(private auditProfileService: AuditProfileService) {
    super();
  }

  override ngOnInit(): void {
    this.cities = [
      { name: 'All time', icon: 'assets/images/svg/eye-filled-light-blue.svg' },
      { name: 'All time 1', icon: 'assets/images/svg/cloud-updated-icon.svg' },
      { name: 'All time 2', icon: 'assets/images/svg/trash-icon-light-blue.svg' }
  ];
  }

}
