import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-connection-modal',
  templateUrl: './create-connection-modal.component.html',
  styleUrls: ['./create-connection-modal.component.css']
})
export class CreateConnectionModalComponent implements OnInit{

  @Input() isSaveViewModalVisible!: any;
  @Input() connectionName!: string;
  @Output() viewNameEnteredEventEmitter = new EventEmitter<string>();
  errorMsg: string = '';
  constructor() { }
  ngOnInit() {
    this.isSaveViewModalVisible;
  }
  saveView() {
    if (this.connectionName == undefined || this.connectionName == '') {
      this.errorMsg = 'Please enter new connection name';
      return;
    }else{
      this.errorMsg = '';
    }

    this.viewNameEnteredEventEmitter.emit(this.connectionName);
  }
  cancel() {
  this.viewNameEnteredEventEmitter.emit('');
  }

}
