<p-dialog header="Header" [modal]="true" [(visible)]="visible" (onHide)="onDialogHide()" [style]="{width: '65vw'}">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <div class="left-border left-border-bg mr-2"></div>
            <div class="d-flex-d-column margin-left-5">
                <span class="user-title-text">Users</span>
                <span class="user-text-regular">Total No. of Event <span class="user-text-bold">(505)</span></span>
            </div>
        </div>
        <div>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText class="search-input" placeholder="Search by name or ID" />
            </span>
        </div>
        <div></div>
        <div></div>
        <div class="">
            <p-button class="save-btn" label="Save" severity="secondary"></p-button>
        </div>
        <div>
            <p-button class="filter-btn" icon="pi pi-fw pi-filter"></p-button>
        </div>
    </ng-template>
    <div class="users-data-table">
        <p-table styleClass="custom-table" [value]="products" [(selection)]="selectedProducts" dataKey="code" [paginator]="true" [scrollable]="true" [rows]="10" [selectionPageOnly]="true" [tableStyle]="{'min-width': '40rem'}">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:200px">Event type</th>
                    <th style="min-width:200px">ID</th>
                    <th style="min-width:200px">First Name</th>
                    <th style="min-width:200px">Last Name</th>
                    <th style="min-width:200px">Modified</th>
                    <th style="min-width:200px">Quantity</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td class="d-flex align-items-center">{{product.code}}<div class="event-colorful-boxes"></div>
                    </td>
                    <td>{{product.name}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.category}}</td>
                    <td>{{product.quantity}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>