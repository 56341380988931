import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AnomalyDetectionComponent } from './pages/features/anomaly-detection/anomaly-detection.component';
import { RealTimeNotificationsComponent } from './pages/features/real-time-notifications/real-time-notifications.component';
import { LighthouseDashboardComponent } from './pages/features/_lighthouse/lighthouse-dashboard/lighthouse-dashboard.component';
import { LighthouseDataExplorerComponent } from './pages/features/_lighthouse/lighthouse-data-explorer/lighthouse-data-explorer.component';
import { LighthouseInterstatialComponent } from './pages/features/_lighthouse/lighthouse-interstatial/lighthouse-interstatial.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'anomaly-detection', component: AnomalyDetectionComponent },
  { path: 'real-time-notifications', component: RealTimeNotificationsComponent },
  { path: 'lighthouse-dashboard', component: LighthouseDashboardComponent },
  { path: 'lighthouse-data-explorer', component: LighthouseDataExplorerComponent },
  { path: 'lighthouse-interstatial', component: LighthouseInterstatialComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
