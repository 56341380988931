import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditLighthouseDto } from '../../models/IAuditLighthouseDto';
import { baseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class AuditLighthouseService extends baseService {

  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async createAuditLighthouseDatabase(auditLighthouseDto: IAuditLighthouseDto) {
    this.apiRoute = 'auditlighthouse';
    return this.post(`/${this.apiRoute}`, auditLighthouseDto).toPromise();
  }
}
