<div class="m-4">
    <span style="font-size: 28px; color: #272B30;font-weight: bold;">Dashboard Reports</span>
    <div class="d-flex mt-2 gap-2 w-100 panel">
        <p-panel [toggleable]="true" style="width: 100%; margin-bottom: 20px;" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
            <ng-template pTemplate="header"> 
                <div class="flex">
                    <div><span class="text-primary font-bold text-xxl">Overview</span></div>
                    <div>
                        <p-dropdown [options]="cities">
                            <ng-template let-item pTemplate="selectedItem">
                                <!-- <img src="{{item.icon}}" alt=""> -->
                                {{item.name}}
                           </ng-template>
                           <ng-template let-object pTemplate="item">
                                <img src="{{object.icon}}" alt="">
                                {{object.name}}
                           </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                
                
            </ng-template>
            
            <div class="flex-3 flex-item bg-white flex-center" style="height: 375px">
                <!-- <div class="flex-2 flex-item border bg-white"> -->
                    <app-overview style="width: 100%;"></app-overview>
                <!-- </div> -->
                <!-- <div class="flex-2 flex-item border bg-white"> -->
                    <app-graph></app-graph>
                <!-- </div> -->
            </div>  
        </p-panel>
        
        <!-- <div class="flex-2 flex-item border bg-white">
            <app-graph></app-graph>
        </div> -->
    </div>
    <div class="audit-panel">
        <p-panel [toggleable]="true" style="width: 100%;" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
            <ng-template pTemplate="header"> 
                <div class="flex">
                    <div class="d-flex gap-3 justify-content-between width-100p">
                        <div><span class="text-primary font-bold text-xxl">Audits</span></div>
                        <div class="d-flex gap-3">
                            <span class="p-input-icon-right" style="height: 100%;">
                              <i class="pi pi-search"></i>
                              <input type="text" class="search-input w-100" placeholder="Search Audits " pInputText />
                            </span>
                            <button class="table-button">Table</button>
                        </div>
                      </div>
                </div>
                
                
            </ng-template>
            <!-- <div class="w-100 bg-white border mt-2 b-radius-24"> -->
                <app-audit></app-audit>
            <!-- </div> -->
        </p-panel>
    </div>
</div>
