import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IAuditLighthouseDbConnectionDto } from './models/IAuditLighthouseDbConnectionDto';
import { IAuditLogEntryRollupDto } from './models/IAuditLogEntryRollupDto';
import { LighthouseDashboardService } from './services/lighthouse-dashbaord/lighthouse-dashboard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'sql-safe-keep';
  // for temp .. we will set or call dynamically header and footer route base where show or hide 
  logged: boolean = false;
  routeSubscriptions: any;

  constructor(private router: Router,
    private lighthouseDashboardService: LighthouseDashboardService) {
    let _ = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL2NvZ25pdGl2ZWdlbmVyYXRpb25lbnRlcnByLmIyY2xvZ2luLmNvbS8wMDMxOTYzMS03MWZhLTQzMWMtYTJkOC0wNjJjMjQ3ZmVlNmQvdjIuMC8iLCJzdWIiOiIzMWU5YmIyYy0zM2I5LTRiNjQtYjgyZC0wMzdmMTE1NTY0M2YiLCJhdWQiOiI4MDE4NTAyZC1hNmRjLTQyNDUtOTQ4Mi0yYzhmNjU5NmU1NjMiLCJleHAiOjE2OTQ5OTEzMDQsIm5vbmNlIjoiZGVmYXVsdE5vbmNlIiwiaWF0IjoxNjk0OTg3NzA0LCJhdXRoX3RpbWUiOjE2OTQ5ODc3MDQsImlkcF9hY2Nlc3NfdG9rZW4iOiJ5YTI5LmEwQWZCX2J5QzZCbzFVSkVwR2lfNzJ1UVl6ZVAyeHo5T2xPcTIyeklqSi1CeGxjYXkyNTlUb3J5dUpqMzBfRzhVYkw0ZExkRGNwVE5reUVFRmhheFR1VTEyaF9WcXNKcWtLSVM0Rk5qSHFzUDF5V2NpZ3IzM0tGQ0JEbGRibGZHbTRoRTVIUlFiRExfVm9VTEtxaDlZZ0Njbko2UUZhLTFKS1N6Wk1hQ2dZS0FhVVNBUkFTRlFHT2NObkNRUmh6SFpUVHBNb3RjQzdRUDNHem1RMDE3MSIsIm5hbWUiOiJSb2JlcnQgR3JlZW4iLCJpZHAiOiJnb29nbGUuY29tIiwib2lkIjoiMzFlOWJiMmMtMzNiOS00YjY0LWI4MmQtMDM3ZjExNTU2NDNmIiwiZW1haWxzIjpbImRvYy5ncmVlbi5yb2JAZ21haWwuY29tIl0sInRmcCI6IkIyQ18xX1NpZ25VcFNpZ25Jbl9QdWJsaWMiLCJuYmYiOjE2OTQ5ODc3MDR9.VinWTUFQI6A2FJy4wg0pGu-qlL9IOb49gxobA1IkzdRrQ0n4zsIG3A2wIQqSIja91QxO8Oh7JlmtSs1nQDUSJhty3HErmmmNASjkBt7rbla_9Q117UFdNzl3-qOesVuStKaKmfphnDIlkkQArjio40qWpc5UFabbneMhqlIkvgtG4ONkfUCuSAHJyV0bgULnicrvEIE2SEiN2-NU8a11Otm4uU_N3wY5zHrc-L-zYlK8eFG_ADfMYM9khdXRWINaROhmLDr_7qONCV7-mORyYm8GB1KFnefkMGqRruWJ6fZfytARFW1mqtrwsZrzMJ9JyL8hTzsKEd4XdyFKoeHihw';
    let token = localStorage.setItem('ssk_id_token', _);

    this.goToDashboard();
  }

  async goToDashboard() {
    //this.router.navigate(['/setup-account']);
    //this.router.navigate(['/setup-project']);
    //this.router.navigate(['/select-project-type']);
    //this.router.navigate(['/service-customers']);
    //this.router.navigate(['/interstitial']);
    
    //await this.getLogEntriesRollupAsync().then(() => {
    //  console.log(`data`, this.data);
    //  //this.router.navigate(['/home']);
    //});
  }

  public data: any[] = [];

  //private async getLogEntriesRollupAsync() {
  //  let _: IAuditLighthouseDbConnectionDto = {} as IAuditLighthouseDbConnectionDto;
  //  _.ConnectionString = 'Server=localhost;Initial Catalog=homeazzon-local;Integrated Security=True;TrustServerCertificate=True;Connection Timeout=30;';
  //  _.AuditLighthouseDatabaseName = 'SampleDB_Audit_Lighthouse';

  //  await this.lighthouseDashboardService.getLogEntriesRollupAsync(_).then((x: Array<IAuditLogEntryRollupDto>) => {
  //    x.map((a) => {
  //      this.data.push({
  //        tableName: a.SourceAuditTable,
  //        totalEvents: a.TableRecordCount,
  //        lastEvent: a.LastUpdated,
  //        insertions: 0,
  //        updates: 0,
  //        deletions: 0,
  //        selected: false
  //      });
  //    });
  //  }).catch((err) => { });
  //}

  ngOnInit() {
    this.routeSubscriptions = this.router.events.subscribe((val) => {
      let routerStartChage = val instanceof NavigationStart
      if (routerStartChage) {
        let event = val as NavigationStart;
        if (event !== undefined && event !== null) {
          this.logged = event.url !== '/';
        }
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscriptions ? this.routeSubscriptions.unsubscribe() : '';
  }
}
