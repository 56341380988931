<ng-container *ngIf="logged; else elseBlock">
    <!--Temp Comde-->
    <div class="w-100 d-flex flex-row overflow-hidden">
        <div>
            <app-side-nav></app-side-nav>
        </div>
        <div class="w-100">
            <div class="bg-white w-100" style="height: 100px;">
                <app-header></app-header>
            </div>
            <div class="w-100" style="height: 86vh; padding-left: 0rem; overflow: overlay;">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #elseBlock>
    <router-outlet></router-outlet>
</ng-template>
