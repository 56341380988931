<p-dialog header="Header" [modal]="true" [(visible)]="productVisible" (onHide)="onDialogHide()" [style]="{width: '60vw'}">
    <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
            <span class="icon-container-logo">
                <img alt="data-table-icon" src="../../../../../assets/images/svg/database-icon.svg"
                    style="width: 2rem" />
            </span>
            <div class="d-flex-d-column margin-left-5">

                <span class="user-title-text">Access Log 1</span>
                <span class="user-text-regular">Total No. of Event <span class="user-text-bold">(50)</span></span>
            </div>
        </div>
        <div></div>
        <div></div>
        <div>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText class="search-input" placeholder="Search by name or ID" />
            </span>
        </div>
        <div></div>
        <div></div>
        <div class="">
            <p-button class="save-btn" label="Save" severity="secondary"></p-button>
        </div>
        <div>
            <p-button class="filter-btn" icon="pi pi-fw pi-filter"></p-button>
        </div>

        <div>
            <p-button class="filter-btn" icon="pi pi-ellipsis-h"></p-button>
        </div>
    </ng-template>
    <div class="products-data-table scroll-container">
        <p-table styleClass="custom-table"
                 [value]="products"
                 [(selection)]="selectedProducts"
                 dataKey="code"
                 [paginator]="true"
                 [scrollable]="true"
                 [rows]="20"
                 [selectionPageOnly]="true"
                 [tableStyle]="{'min-width': '40rem'}">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:100px">ID</th>
                    <th style="min-width:100px">Name</th>
                    <th style="min-width:100px">SKU</th>
                    <th style="min-width:100px">Model</th>
                    <th style="min-width:100px">Weight</th>
                    <th style="min-width:100px">Height</th>
                    <th style="min-width:100px">Size</th>
                    <th style="min-width:100px">Color</th>
                    <th style="min-width:100px">--------</th>
                    <th style="min-width:100px">--------</th>
                    <th style="min-width:100px">test</th>
                    <th style="min-width:100px">test 1</th>
                    <th style="min-width:100px">--------</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{product.id}}</td>
                    <td>{{product.code}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.category}}</td>
                    <td>{{product.quantity}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.category}}</td>
                    <td>{{product.quantity}}</td>
                    <td>{{product.dashescol}}</td>
                    <td>{{product.dashescol}}</td>
                    <td>{{product.dashescol}}</td>
                    <td>{{product.dashescol}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-dialog>
