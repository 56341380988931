import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { IAuditDto } from '../../../../models/IAuditDto';
import { IAuditLighthouseDbConnectionDto } from '../../../../models/IAuditLighthouseDbConnectionDto';
import { IAuditLogEntryTimelineDetailsDto } from '../../../../models/IAuditLogEntryTimelineDetailsDto';
import { IAuditLogEntryTimelineDto } from '../../../../models/IAuditLogEntryTimelineDto';
import { ITableColumnsDto } from '../../../../models/ITableColumnsDto';
import { DataExplorerService } from '../../../../services/data-explorer/data-explorer.service';
import { BaseComponent } from '../../../base/base.component';
import { IAuditTableQueryDto } from 'src/app/models/IAuditTableQueryDto';
import { HeaderService } from 'src/app/services/_app/header/header.service';
import { Subscription } from 'rxjs';
import { ILighthouseViewDto } from '../../../../models/ILighthouseViewDto';
import { IFilterDto } from '../../../../models/IFilterDto';

@Component({
  selector: 'app-lighthouse-data-explorer',
  templateUrl: './lighthouse-data-explorer.component.html',
  styleUrls: ['./lighthouse-data-explorer.component.css']
})
export class LighthouseDataExplorerComponent extends BaseComponent implements OnDestroy {

  @ViewChild('scrollableElement', { static: true }) scrollableElement!: ElementRef;

  @HostListener('scroll', ['$event'])

  outlined: any;
  tableDialogVisible = false;
  visible = false;
  public isCollapsed: boolean = false;
  public isViewMode: boolean = false;
  public databaseName: string = '';
  public connectionString: string = '';
  public all_tableCount: number = 0;
  public a_to_e_tableCount: number = 0;
  public f_to_j_tableCount: number = 0;
  public k_to_o_tableCount: number = 0;
  public p_to_t_tableCount: number = 0;
  public u_to_z_tableCount: number = 0;
  public isAllTableCountSelected: boolean = true;
  public is_A_to_E_TableCountSelected: boolean = false;
  public is_F_to_J_TableCountSelected: boolean = false;
  public is_K_to_O_TableCountSelected: boolean = false;
  public is_P_to_T_TableCountSelected: boolean = false;
  public is_U_to_Z_TableCountSelected: boolean = false;
  public a_to_e_tables: any = [];
  public f_to_j_tables: any = [];
  public k_to_o_tables: any = [];
  public p_to_t_tables: any = [];
  public u_to_z_tables: any = [];

  public tablesInViewCount: number = 0;
  public tablesInView: Array<any> = [];
  private isAwaitingData: boolean = false;
  private isInViewModeSubscription: Subscription;
  private loadSavedViewSubscription: Subscription;
  private deleteSavedViewSubscription: Subscription;

  public showFilterModal: boolean = false;
  resultMatch: any;
  dataView: boolean = false;
  isSaveViewModal: boolean = false;

  skeleton:boolean = true;

  constructor(private dataExplorerService: DataExplorerService,
    private headerService: HeaderService) {
    super();

    this.headerService.updateIsShowSavedViews(false);
    this.isInViewModeSubscription = this.headerService.isInViewMode$.subscribe(isInViewMode => {
      this.isViewMode = isInViewMode;
      if (!isInViewMode) {
        this.tablesInView = [];
        this.tablesInViewCount = 0;
        this.showFilteredTables('all');
      }
    });
    this.loadSavedViewSubscription = this.headerService.savedView$.subscribe(savedView => {
      if (savedView != undefined
        && savedView != null
        && !this.isEmptyObject(savedView)) {
        this.loadSavedView(savedView);
      }
    });
    this.deleteSavedViewSubscription = this.headerService.deletedSavedView$.subscribe(savedView => {
      if (savedView != undefined
        && savedView != null
        && !this.isEmptyObject(savedView)) {
        this.deleteSavedView(savedView);
      }
    });

    let _ = this.Audit;
    this.databaseName = _.DatabaseName;
    this.connectionString = _.ConnectionString;
  }

  isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  override async ngOnInit() {
    //temp for view UI search layer
    setTimeout(() => {
      this.isSaveViewModal = false;
    }, 4000)
    await this.getLogEntriesRollupAsync();
    await this.getAuditProfileViews();
  }

  items: MenuItem[] = [
    {
      label: 'Rename Table',
      icon: 'pi pi-pencil'
    },
    {
      label: 'View Data-table',
      icon: 'pi pi-eye',
      command: () => this.showTableViewtDialog()
    },
    {
      label: 'Move to new section',
      icon: 'pi pi-folder',
    },
    {
      label: 'Delete forever',
      icon: 'pi pi-trash'
    }
  ];

  public async onScroll(event: Event, tableName: string): Promise<void> {
    let data = document.getElementById(`${tableName}_data`);
    var divHeight = data ? data.offsetHeight : 0;

    const verticalScrollPosition = (event.target as HTMLElement).scrollTop;
    //const horizontalScrollPosition = (event.target as HTMLElement).scrollLeft;

    verticalScrollPosition > divHeight / 2 ? console.log(`scrolled 50% ${tableName}`) : console.log(`scrolling ${tableName}`);

    // When 50% of the div is scrolled vertically
    // get more data from the server
    if (verticalScrollPosition > divHeight / 2 && !this.isAwaitingData) {
      /*
      this.isAwaitingData = true;
      let a: IAuditTableQueryDto = {} as IAuditTableQueryDto;
      a.AuditProfileId = this.Audit.Id;
      a.TableName = tableName;
      a.Filter = '';

      await this.dataExplorerService.getTableDataAsync(a).then((x: any) => {

        x.map((row: any) => {
          let tableUiRow: any = {
            auditType: row.AuditType,
            id: row.AuditId,
            modifiedBy: row.ModifiedBy,
            modifiedDate: row.ModifiedDate,
            tableColumns: row.TableColumns
          };

          this.allTables.find(x => x.name === tableName).data.push(tableUiRow);
        });

        //console.log(x);
        this.isAwaitingData = false;
      }).catch((err: any) => {
        console.log(err);
      });
      */
      await this.GetTableData(tableName);
    }
  }

  private async GetTableData(tableName: string) {
    this.isAwaitingData = true;
    let a: IAuditTableQueryDto = {} as IAuditTableQueryDto;
    a.AuditProfileId = this.Audit.Id;
    a.TableName = tableName;
    a.Filter = {} as IFilterDto;

    await this.dataExplorerService.getTableDataAsync(a).then((x: any) => {

      x.map((row: any) => {
        let tableUiRow: any = {
          AuditType: row.AuditType,
          AuditId: row.AuditId,
          ModifiedBy: row.ModifiedBy,
          ModifiedDate: row.ModifiedDate,
          TableColumns: row.TableColumns
        };

        this.allTables.find(x => x.name === tableName).data.push(tableUiRow);
      });

      this.isAwaitingData = false;
    }).catch((err: any) => {
      console.log(err);
    });
  }

  public allTables: Array<any> = [];
  public viewableTables: Array<any> = [];
  private logs: Array<any> = [];

  reverseLog = [...this.logs].sort((a, b) => b.id - a.id);

  rowClass(index: number): string {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  }

  // Gets the data for all the tables in the Audit DB (500 records at a time per table)
  private async getLogEntriesRollupAsync() {
    let a: IAuditDto = this.Audit;

    await this.dataExplorerService.getLogEntriesTimelineDetailsAsync(a.Id).then((x: Array<Array<IAuditLogEntryTimelineDetailsDto>>) => {
      x.map((a) => {
        let o: any = {
          name: '',
          data: []
        };
        o.name = a[0].TableName;

        const firstLetter = o.name[0].toUpperCase();
        this.all_tableCount += 1;

        if ('A' <= firstLetter && firstLetter <= 'E') {
          this.a_to_e_tableCount += 1;
          this.a_to_e_tables.push(o.name);
        } else if ('F' <= firstLetter && firstLetter <= 'J') {
          this.f_to_j_tableCount += 1;
          this.f_to_j_tables.push(o.name);
        } else if ('K' <= firstLetter && firstLetter <= 'O') {
          this.k_to_o_tableCount += 1;
          this.k_to_o_tables.push(o.name);
        } else if ('P' <= firstLetter && firstLetter <= 'T') {
          this.p_to_t_tableCount += 1;
          this.p_to_t_tables.push(o.name);
        } else if ('U' <= firstLetter && firstLetter <= 'Z') {
          this.u_to_z_tableCount += 1;
          this.u_to_z_tables.push(o.name);
        }

        a.map((r) => {
          let z: any = {
            AuditType: r.AuditType,
            AuditId: r.AuditId,
            ModifiedBy: r.ModifiedBy,
            ModifiedDate: r.ModifiedDate,
            TableColumns: r.TableColumns
          }
          o.data.push(z);
        });

        this.allTables.push(o);
      });

      this.viewableTables = this.allTables;
      this.skeleton = false;
    }).catch((err: any) => {
      this.skeleton = false;
      console.log(err);
    });
  }

  // TODO: Fix typo
  showTableViewtDialog() {
    this.visible = true;
  }

  close(event: boolean) {
    this.visible = !event;
    this.tableDialogVisible = !event;
  }

  openMenu(menu: any, event: any) {
    menu.toggle(event);
  }

  showFilteredTables(selectedFilter: string) {
    this.isAllTableCountSelected = false;
    this.is_A_to_E_TableCountSelected = false;
    this.is_F_to_J_TableCountSelected = false;
    this.is_K_to_O_TableCountSelected = false;
    this.is_P_to_T_TableCountSelected = false;
    this.is_U_to_Z_TableCountSelected = false;

    switch (selectedFilter) {
      case 'all':
        this.isAllTableCountSelected = true;
        this.viewableTables = this.allTables;
        break;
      case 'a_to_e':
        this.is_A_to_E_TableCountSelected = true;
        this.viewableTables = this.allTables.filter(x => this.a_to_e_tables.includes(x.name));
        break;
      case 'f_to_j':
        this.is_F_to_J_TableCountSelected = true;
        this.viewableTables = this.allTables.filter(x => this.f_to_j_tables.includes(x.name));
        break;
      case 'k_to_o':
        this.is_K_to_O_TableCountSelected = true;
        this.viewableTables = this.allTables.filter(x => this.k_to_o_tables.includes(x.name));
        break;
      case 'p_to_t':
        this.is_P_to_T_TableCountSelected = true;
        this.viewableTables = this.allTables.filter(x => this.p_to_t_tables.includes(x.name));
        break;
      case 'u_to_z':
        this.is_U_to_Z_TableCountSelected = true;
        this.viewableTables = this.allTables.filter(x => this.u_to_z_tables.includes(x.name));
        break;
    }
  }

  /////////////////// Views
  //////////////////////

  currentSavedView: string = '';
  currentSavedViewId: string = '';

  private async getAuditProfileViews() {
    let a = this.Audit;
    await this.dataExplorerService.getLighthouseViewsAsync(a.DatabaseName).then((x: Array<any>) => {
      this.headerService.setSavedViews(x);
    }).catch((err: any) => {

    });
  }

  addTableToView(table: any) {
    if (this.tablesInViewCount === 0) {
      this.headerService.updateIsInViewMode(true);
    }

    this.isViewMode = true;
    this.tablesInView.push(table);
    this.tablesInViewCount += 1;
  }

  removeTableFromView(table: any) {
    this.tablesInView = this.tablesInView.filter(x => x.name !== table.name);
    this.tablesInViewCount -= 1;

    this.checkIfNeedToExitViewMode();
  }

  checkIfNeedToExitViewMode() {
    if (this.tablesInViewCount === 0) {
      this.isViewMode = false;
      this.headerService.updateIsInViewMode(false);
      this.showFilteredTables('all');
    }
  }

  isTableInView(table: any): boolean {
    return this.tablesInView.includes(table);
  }

  async saveView() {
    this.isSaveViewModal = true;
  }

  addNewView() {
    this.dataView = !this.dataView;
  }

  seeSavedViews() {
    this.headerService.updateIsShowSavedViews(true);
  }

  async deleteSavedView(savedView: any) {
    let _ = this.Audit;

    await this.dataExplorerService.deleteViewAsync(savedView.Id, _.DatabaseName).then(async (x) => {
      await this.getAuditProfileViews();
    }).catch((err) => {

    });
  }

  seeAllViewTables() {
    this.viewableTables = this.tablesInView;
  }

  udpateViewTables() {
    this.viewableTables = this.tablesInView;
  }

  removeFromTablesInView(table: any) {
    this.tablesInView = this.tablesInView.filter(x => x.name !== table.name);
    this.tablesInViewCount -= 1;

    this.udpateViewTables();
    this.checkIfNeedToExitViewMode();
  }

  loadSavedView(savedView: any) {
    this.currentSavedView = savedView.ViewName;
    this.currentSavedViewId = savedView.Id;

    this.tablesInView = [];
    this.tablesInViewCount = 0;

    this.viewableTables = this.allTables.filter(x => savedView.Tables.includes(x.name));
    this.tablesInView = this.viewableTables;
    this.tablesInViewCount = this.tablesInView.length;

    if (this.tablesInViewCount > 0) {
      this.headerService.updateIsInViewMode(true);
      this.isViewMode = true;
    }
  }

  async handleViewNameSaved(event: string) {
    let tableArray: any = [];

    this.tablesInView.map((x) => {
      tableArray.push(x.name);
    });

    let _: any = {
      AuditProfileId: this.Audit.Id,
      Tables: tableArray,
      DatabaseName: this.databaseName,
      ViewName: event,
      Id: this.currentSavedViewId
    };

    this.isSaveViewModal = false;
    this.currentSavedView = '';
    this.currentSavedViewId = '';

    await this.dataExplorerService.saveViewAsync(_).then(async (x: any) => {
      await this.getAuditProfileViews();
    }).catch((err: any) => {
      console.log(err);
    });

    console.log(_);
  }

  /////////////////// end: Views
  //////////////////////

  tableColumnsForFilter: any;
  currentTableForFilter: string = '';

  toggleFilterModal(table: any) {
    if (this.showFilterModal) {
      this.showFilterModal = false;
      this.currentTableForFilter = '';
    } else {
      this.currentTableForFilter = table.name;
      this.showFilterModal = true;

      let x: any = this.getColumnNames(table.data);
      this.tableColumnsForFilter = x.slice().sort();

      let result = this.viewableTables.filter(x => x.name == table.name);
      this.resultMatch = result[0];

      console.log(this.resultMatch);
    }
  }

  async applyFilter(event: any) {
    this.isAwaitingData = true;
    let a: IAuditTableQueryDto = {} as IAuditTableQueryDto;
    a.AuditProfileId = this.Audit.Id;
    a.TableName = this.currentTableForFilter;
    a.Filter = this.convertToIFilterDto(event);

    await this.dataExplorerService.getTableDataAsync(a).then((x: any) => {

      this.allTables.find(x => x.name === this.currentTableForFilter).data = [];

      x.map((row: any) => {
        let tableUiRow: any = {
          AuditType: row.AuditType,
          AuditId: row.AuditId,
          ModifiedBy: row.ModifiedBy,
          ModifiedDate: row.ModifiedDate,
          TableColumns: row.TableColumns
        };

        this.allTables.find(x => x.name === this.currentTableForFilter).data.push(tableUiRow);
      });

      // close filter modal
      this.showFilterModal = false;
      this.currentTableForFilter = '';

      this.isAwaitingData = false;
    }).catch((err: any) => {
      alert('Invalid query! Check your filter logic!');
      console.log(err);
    });
  }

  getColumnNames(dataArray: any) {
    const columnNames = new Set(); // Using a Set to store unique values

    dataArray.forEach((item: any) => {
      Object.keys(item).forEach(key => {
        if (key !== 'TableColumns') {
          columnNames.add(key); // Add keys from the main object
        }
      });

      // Assuming `tableColumns` is an array of objects with a property `name`
      if (Array.isArray(item.TableColumns)) {
        item.TableColumns.forEach((col: any) => {
          columnNames.add(col.Name); // Add the 'name' from the tableColumns array
        });
      }
    });

    return [...columnNames]; // Convert Set back to Array
  }

  public collapseExpandTables() {
    this.isCollapsed = !this.isCollapsed
  }

  ngOnDestroy(): void {
    this.isInViewModeSubscription.unsubscribe();
    this.loadSavedViewSubscription.unsubscribe();
    this.deleteSavedViewSubscription.unsubscribe();
  }

  private toPascalCase(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  private convertToIFilterDto(obj: any): any {
    if (Array.isArray(obj)) {
      // Use an arrow function to maintain the correct `this` context
      return obj.map((o) => this.convertToIFilterDto(o));
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc: any, key: string) => {
        const pascalKey = this.toPascalCase(key);
        acc[pascalKey] = this.convertToIFilterDto(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  }
}
