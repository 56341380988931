import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lighthouse-interstatial',
  templateUrl: './lighthouse-interstatial.component.html',
  styleUrls: ['./lighthouse-interstatial.component.css']
})
export class LighthouseInterstatialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
