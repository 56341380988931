<section class="overview">
    <!-- //1st row dropdown portion -->
    <!-- <div class="w-100 d-flex align-items-center justify-centent-between mb-4"> -->
        <!-- <div class="w-100">
            <div class="left">
                <p class="left-cont">overview</p>
            </div>
        </div> -->
        <!-- <p-dropdown [options]="cities" optionLabel="name"></p-dropdown> -->
    <!-- </div> -->
    <!-- end -->
    <div class="overview-tab">
        <div class="div">
            <div class="info mx-3">
                <div class="icon-place-holder">
                    <div class="group">
                        <img class="UI-icon-database" src="assets/images/svg/dataBase.svg" />
                    </div>
                </div>
                <div class="frame">
                    <div class="caption">
                        <img class="img" src="assets/images/svg/info-icon.svg" />
                        <div class="customers">Total Audits</div>
                    </div>
                    <div class="element">1024</div>
                </div>
            </div>
            <div class="component">
                <img class="img" src="assets/images/svg/down.svg" />
                <div class="text-wrapper">37.8%</div>
            </div>
        </div>
        <div class="div-2">
            <div class="info mx-3">
                <div class="icon-place-holder-2">
                    <div class="group">
                        <img class="UI-icon-group-light" src="assets/images/svg/user-pink.svg" />
                    </div>
                </div>
                <div class="frame">
                    <div class="caption">
                        <img class="img" src="assets/images/svg/info-icon.svg" />
                        <div class="customers">User Mngmt</div>
                    </div>
                    <div class="element">256k</div>
                </div>
            </div>
            <div class="component-2">
                <img class="img" src="assets/images/svg/up.svg" />
                <div class="text-wrapper-2">37.8%</div>
            </div>
        </div>
    </div>
</section>