import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.css', '../../../pages/dashboard/dashboard.component.css']
})
export class GraphComponent implements OnInit {
    data: any;

    options: any;

    ngOnInit() {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        this.data = {
            labels: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            datasets: [
                {
                    label: 'My First dataset',
                    backgroundColor: [
                        '#E8ECF2',
                        '#E8ECF2',
                        '#E8ECF2',
                        '#E8ECF2',
                        '#E8ECF2',
                        '#669BBC',
                        '#E8ECF2'
                    ],
                    borderSkipped: false,
                    borderRadius: [
                        '8',
                        '8',
                        '8',
                        '8',
                        '8',
                        '8',
                        '8'
                    ],
                    borderColor: documentStyle.getPropertyValue('#B5E4CA'),
                    data: [15, 30, 10, 5, 10, 50, 20],
                    barThickness: 35,
                }
            ]
        };

        this.options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: ''
                    },
                    display: false
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                        display: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false,
                        display: false
                    },
                    display: false
                }

            }
        };
    }

}
