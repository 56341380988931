<div class="save-view-modal">
    <p-dialog header="Please Enter New Connection String"
          [(visible)]="isSaveViewModalVisible"
          (onHide)="cancel()"
          [modal]="true"
          [style]="{ width: '35vw' }"
          [draggable]="false"
          [resizable]="false">
        <div class="modal-body">
            <div class="eye-container">
               <div class="eye-content">
                    <img width="42px" height="34px" src="assets/images/svg/connection.svg" alt="">
               </div> 
            </div>
            <div class="input-box">
                <input class="text" pInputText pKeyFilter="int" [(ngModel)]="connectionName" placeholder="Enter New Connection Name" />
            </div>
            <div class="btn-comntainer1">
                <p-button class="save-btn" label="Save" (click)="saveView()"></p-button> 
            </div>
        </div>
        <span *ngIf="errorMsg" class="errorMsg">{{errorMsg}}</span>
    </p-dialog>
</div>