import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { IAuditDto } from '../../../../models/IAuditDto';
import { AuditProfileService } from '../../../../services/audit-profile/audit-profile.service';
import { BaseComponent } from '../../../base/base.component';
import { AuditUtilityService } from '../../../../services/audit-utility/audit-utility.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],

})
export class AuditComponent extends BaseComponent {

  currentConnectionString: string = '';
  isConnectionModalOpen: boolean = false;


  isModalopen: boolean = false;
  isViewAuditLog: boolean = false;
  auditMetricsData: any;
  skeleton: boolean = true;

  constructor(private auditProfileService: AuditProfileService, private auditUtilityService: AuditUtilityService,
    private router: Router) {
    super();
  }

  override async ngOnInit() {
    await this.getAuditProfiles();
  }

  public auditProfiles: any = [];

  items: MenuItem[] = [
    {
      label: 'Lighthouse Dashboard',
      icon: 'pi pi-sun',
      routerLink: '/lighthouse-dashboard',
    },
    {
      label: 'See full data report',
      icon: 'pi pi-eye',
    },
    {
      label: 'Update connection string',
      icon: 'pi pi-cloud-upload',
    },
    {
      label: 'Delete Audit',
      icon: 'pi pi-trash'
    }
  ];
  toggleItems: MenuItem[] = [
    // {
    //   label: 'Lighthouse Dashboard',
    //   icon: 'pi pi-sun',
    //   routerLink: '/lighthouse-dashboard',
    // },
    //{
    //  label: 'See full data report',
    //  icon: 'pi pi-eye',
    //},
    {
      label: 'Update connection string',
      icon: 'pi pi-cloud-upload',
    },
    {
      label: 'Delete Audit',
      icon: 'pi pi-trash'
    }
  ];

  headercheckbox: boolean = false;
  listcheckbox: boolean = false;

  private async getAuditProfiles() {

    await this.auditProfileService.getAuditProfiles().then((x: Array<IAuditDto>) => {
      x.map((a: any) => {
        this.auditProfiles.push({
          Id: a.Id,
          CustomerId: a.CustomerId,
          ConnectionString: a.ConnectionString,
          DatabaseName: a.DatabaseName,
          ExcludeTables: a.ExcludeTables,
          AuditMetrics: a.AuditMetrics,
          AuditMetricsSummaryBrief: this.getAuditResultsSummaryBrief(a.AuditMetrics[0]),
          AuditTablesCreated: this.getAuditTableCreatedCount(a.AuditMetrics[0])
        });
      });
      this.skeleton = false;
    }).catch((err: any) => {
      console.log(err);
      this.skeleton = false;
    });
  }

  goToLighthouseDashboard(auditProfile: any) {
    let _ = this.Audit;
    _ = auditProfile;
    this.Audit = _;

    this.router.navigate(['/lighthouse-dashboard']);
  }

  viewAuditMetrics(auditProfile: IAuditDto) {
    this.isViewAuditLog = true;
    this.auditMetricsData = auditProfile;
    //this.auditMetricsData.isModalOpenFromAuditComp = true;
  }

  public async deleteAuditProfile(auditProfile: IAuditDto) {
    // TODO: Fix
    await this.auditProfileService.deleteAuditProfile(auditProfile.Id).then((x: any) => {
      // remove the deleted audit profile from the list
      this.auditProfiles = [];
      this.getAuditProfiles();
    }).catch((err: any) => {
      console.log(err);
    });
  }


  public async updateConnectionString(connectionString: string, auditProfile: IAuditDto) {
  
    if (connectionString == null || connectionString == "") {
      // alert("User cancelled the prompt.");
      this.isConnectionModalOpen = false;
      return;
    }
    auditProfile.ConnectionString = connectionString;
    auditProfile.Id

    await this.auditUtilityService.updateConnectionString(auditProfile).then((x: any) => {
      this.refreshAuditProfiles();
    }).catch((err: any) => {
      console.log(err);
    });
    this.currentConnectionString = '';
    this.isConnectionModalOpen = false;
  
  }

  public async OpenConnectionModal() {
    this.isConnectionModalOpen = true;

  }

  refreshAuditProfiles() {
    this.auditProfiles = [];
    this.getAuditProfiles();
  }

  getAuditResultsSummaryBrief(input: string): string {
    // Using a regular expression to extract the desired information
    const successRateMatch = input.match(/Success Rate: (\d+%)/);
    const failureRateMatch = input.match(/Failure Rate: (\d+%)/);
    const auditTablesCreatedMatch = input.match(/Audit Tables Created: (\d+)/);

    // Extracting the captured groups (values) from the regex matches
    const successRate = successRateMatch ? successRateMatch[1] : "N/A";
    const failureRate = failureRateMatch ? failureRateMatch[1] : "N/A";
    const auditTablesCreated = auditTablesCreatedMatch ? auditTablesCreatedMatch[1] : "N/A";

    // Constructing the result string
    return `Success Rate: ${successRate}, Failure Rate: ${failureRate}, Audit Tables Created: ${auditTablesCreated}`;
  }

  getAuditTableCreatedCount(input: string): string {
    const auditTablesCreatedMatch = input.match(/Audit Tables Created: (\d+)/);
    const auditTablesCreated = auditTablesCreatedMatch ? auditTablesCreatedMatch[1] : "N/A";

    return auditTablesCreated;
  }

  // TODO: What is this?
  headerCheckbox() {
    this.headercheckbox = !this.headercheckbox;
  }

  // TODO: What is this?
  listCheckbox() {
    this.listcheckbox = !this.listcheckbox;
  }

  openMenu(menu: any, event: any) {
    menu.toggle(event);
  }

  dialogCloseEmitter(event: boolean) {
    this.isModalopen = event;
  }

  auditMetricsDialogCloseEmitter(event: boolean) {
    this.isViewAuditLog = false;
  }
}
