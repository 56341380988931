import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseService } from '../base.service';
import { IAuditTableQueryDto } from 'src/app/models/IAuditTableQueryDto';
import { ILighthouseViewDto } from '../../models/ILighthouseViewDto';

@Injectable({
  providedIn: 'root'
})
export class DataExplorerService extends baseService {


  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async getLogEntriesTimelineDetailsAsync(auditProfileId: string) {
    this.apiRoute = `auditlighthouse/${auditProfileId}/logentries/timeline/details`;
    return this.get(`/${this.apiRoute}`).toPromise();
  }

  async saveViewAsync(_: any) {
    this.apiRoute = `auditlighthouse/view`;
    return this.post(`/${this.apiRoute}`, _).toPromise();
  }

  async getLighthouseViewsAsync(databaseName: string) {
    this.apiRoute = `auditlighthouse/${databaseName}/views`;
    return this.get(`/${this.apiRoute}`).toPromise();
  }

  async getTableDataAsync(auditTableQuery: IAuditTableQueryDto) {
    this.apiRoute = `dataexplorer/tabledata`;
    return this.post(`/${this.apiRoute}`, auditTableQuery).toPromise();
  }

  //async deleteViewAsync(a: ILighthouseViewDto) {
  //  this.apiRoute = `auditlighthouse/view/delete`;
  //  return this.post(`/${this.apiRoute}`, a).toPromise();

  //  // deleteViewAsync(viewId: string)
  //  // this.apiRoute = `auditlighthouse/view/${viewId}`;
  //  // return this.post(`/${this.apiRoute}`).toPromise();
  //}

  async deleteViewAsync(viewId: string, databaseName: string) {
    this.apiRoute = `auditlighthouse/${databaseName}/view/${viewId}`;
    return this.delete(`/${this.apiRoute}`).toPromise();
  }
}
