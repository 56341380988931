import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  private isInViewMode = new BehaviorSubject<boolean>(false);
  private isShowSavedViews = new BehaviorSubject<boolean>(false);
  private savedViews = new BehaviorSubject<Array<any>>([]);
  private savedView = new BehaviorSubject<any>({});
  private deletedSavedView = new BehaviorSubject<any>({});

  // Observe
  isInViewMode$ = this.isInViewMode.asObservable();
  isShowSavedViews$ = this.isShowSavedViews.asObservable();
  savedViews$ = this.savedViews.asObservable();
  savedView$ = this.savedView.asObservable();
  deletedSavedView$ = this.deletedSavedView.asObservable();

  // Update
  updateIsInViewMode(isInViewMode: boolean) {
    this.isInViewMode.next(isInViewMode);
  }

  updateIsShowSavedViews(isShowSavedViews: boolean) {
    this.isShowSavedViews.next(isShowSavedViews);
  }

  setSavedViews(savedViews: Array<any>) {
    this.savedViews.next(savedViews);
  }

  loadSavedView(savedView: any) {
    this.savedView.next(savedView);
  }

  deleteSavedView(savedView: any) {
    this.deletedSavedView.next(savedView);
  }
}
