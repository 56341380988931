import { Component } from '@angular/core';

@Component({
  selector: 'app-audit-activity-overview',
  templateUrl: './audit-activity-overview.component.html',
  styleUrls: ['./audit-activity-overview.component.css'],

})
export class AuditActivityOverviewComponent {
  filter!: { name: string; code: string; }[];
  days!: { name: string }[];

  ngOnInit() {

    this.filter = [
      { name: 'Filter option', code: 'id here to send backend/filter by' },
      { name: 'option-2', code: 'id here to send backend/filter by' },
      { name: 'option-3', code: 'id here to send backend/filter by' }
    ];
    this.days = [
      { name: 'Last 7 days' },
      { name: 'Previous day' },
      { name: 'Previous Month' }
    ];

  }

}
