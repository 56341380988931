import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuditLighthouseDbConnectionDto } from '../../models/IAuditLighthouseDbConnectionDto';
import { IUserRoleReplicationDto } from '../../models/IUserRoleReplicationDto';
import { IUtilityDto } from '../../models/IUtility';
import { baseService } from '../base.service';
import { IAuditDto } from '../../models/IAuditDto';

@Injectable({
  providedIn: 'root'
})
export class AuditUtilityService extends baseService {

  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async validateConnectionString(utilityDto: IUtilityDto) {
    this.apiRoute = 'auditutility/validateconnection';
    return this.post(`/${this.apiRoute}`, utilityDto).toPromise();
  }

  async getDatabases(utilityDto: IUtilityDto) {
    this.apiRoute = 'auditutility/getdbs';
    return this.post(`/${this.apiRoute}`, utilityDto).toPromise();
  }

  async getDatabaseTables(utilityDto: IUtilityDto) {
    this.apiRoute = 'auditutility/gettables';
    return this.post(`/${this.apiRoute}`, utilityDto).toPromise();
  }

  async replicateUsersAndRoles(utilityDto: IUserRoleReplicationDto) {
    this.apiRoute = 'auditutility/replicateusersandroles';
    return this.post(`/${this.apiRoute}`, utilityDto).toPromise();
  }

  async updateConnectionString(auditDto: IAuditDto) {
    this.apiRoute = 'auditutility/updateconnection';
    return this.post(`/${this.apiRoute}`, auditDto).toPromise();
  }
  

}
