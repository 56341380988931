<section class="overview">
    <!-- 1st row dropdown portion -->
    <div class="justify-content mb-5">
        <div class="d-flex">
            <div class="left-border"></div>
            <p class="title">Audit Activity Overview</p>
        </div>
        <div class="right-algin">
            <p-dropdown [options]="filter" optionLabel="name"></p-dropdown>
            <p-dropdown [options]="days" optionLabel="name"></p-dropdown>
            <div class="icon-place-holder-2"><img class="UI-icon-group-light" src="assets/images/svg/more.svg" /></div>
        </div>

    </div>
    <!-- end -->
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-3 col-border">
                <div class="div">
                    <div class="info mx-3">
                        <div class="mb-15">
                            <div class="icon-place-holder icon-charge">
                                <div class="group">
                                    <div class="overlap">
                                        <img src="assets/images/svg/charge.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-15">
                            <div class="frame mb-15">
                                <div class="caption flex-align-center mb-15">
                                    <div class="customers">Total Audits</div>
                                    <img class="img" src="assets/images/svg/warning.svg" />
                                </div>
                                <div class="element-number">1024</div>
                            </div>
                            <div class="component flex-align-center mb-15">
                                <img class="img" src="assets/images/svg/up.svg" />
                                <span class="text-percent">37.8%</span>
                                <span class="text-wrapper">this week</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-border pl-35">
                <div class="div">
                    <div class="info mx-3">
                        <div class="mb-15">
                            <div class="icon-place-holder insertion-icon">
                                <img class="icon-size" src="assets/images/svg/insertion-icon.svg" />
                            </div>
                        </div>
                        <div class="mb-15">
                            <div class="frame mb-15">
                                <div class="caption flex-align-center mb-15">
                                    <div class="customers">Total Audits</div>
                                    <img class="img" src="assets/images/svg/warning.svg" />
                                </div>
                                <div class="element-number">1024</div>
                            </div>
                            <div class="component flex-align-center mb-15">
                                <img class="img" src="assets/images/svg/up.svg" />
                                <span class="text-percent">37.8%</span>
                                <span class="text-wrapper">this week</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-border pl-35">
                <div class="div">
                    <div class="info mx-3">
                        <div class="mb-15">
                            <div class="icon-place-holder update-icon">
                                <img class="icon-size" src="assets/images/svg/update-icon.svg" />
                            </div>
                        </div>
                        <div class="mb-15">
                            <div class="frame mb-15">
                                <div class="caption flex-align-center mb-15">
                                    <div class="customers">Total Audits</div>
                                    <img class="img" src="assets/images/svg/warning.svg" />
                                </div>
                                <div class="element-number">1024</div>
                            </div>
                            <div class="component flex-align-center mb-15">
                                <img class="img" src="assets/images/svg/up.svg" />
                                <span class="text-percent">37.8%</span>
                                <span class="text-wrapper">this week</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 pl-35">
                <div class="div">
                    <div class="info mx-3">
                        <div class="mb-15">
                            <div class="icon-place-holder deletion-icon">
                                <img class="icon-size" src="assets/images/svg/deletion.svg" />
                            </div>
                        </div>
                        <div class="mb-15">
                            <div class="frame mb-15">
                                <div class="caption flex-align-center mb-15">
                                    <div class="customers">Total Audits</div>
                                    <img class="img" src="assets/images/svg/warning.svg" />
                                </div>
                                <div class="element-number">1024</div>
                            </div>
                            <div class="component flex-align-center mb-15">
                                <img class="img" src="assets/images/svg/up.svg" />
                                <span class="text-percent">37.8%</span>
                                <span class="text-wrapper">this week</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>