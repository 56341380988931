<div *ngIf="hideAnimated" class="arrow-parent {{visible ? 'fadeIn show':'fadeOut'}}">
  <div class="arrow-up"></div>
  <div class="content-container">

    <p-calendar placeholder="Start Date" class="date-field start-date" [showIcon]="true" appendTo="body">
  </p-calendar>

  <p-calendar placeholder="End Date" class="date-field end-date" [showIcon]="true" appendTo="body">
  </p-calendar>

    <div class="d-flex align-items-center" style="gap: 4px;">
      <span class="remove-text">Remove Columns</span>
      <i class="pi pi-info-circle"></i>
    </div>
    <div class="input-dropdown-with-capsule-values">
      <div class="input-box">
        <span class="chip-container" *ngIf="excludedColumns.length > 0">
          <i class="pi input-chip" *ngFor="let excludedColumn of excludedColumns">
            <div class="chip-design">{{excludedColumn.name}}
              <i (click)="removeFromExcludeColumnList(excludedColumn)" style="cursor:pointer;">
                <img src="assets/images/svg/chip.svg" alt="Chip Icon" />
              </i>
              </div>
          </i>
        </span>
        <br />
        <span class="p-input-icon-right input-container">
          <p-dropdown (onChange)="addToExcludeColumnList($event)"
                      [options]="columnOptions"
                      optionLabel="name"
                      placeholder="Select columns to exclude"></p-dropdown>
        </span>
      </div>
    </div>
    <br />
    <ng-container>
      <form [formGroup]="filterForm" (ngSubmit)="applyFilters()">
        <div formArrayName="conditions">
          <ng-container *ngFor="let condition of conditions.controls; let i = index" [formGroupName]="i">
            <div class="row mt-2">
              <!-- Column Dropdown -->
              <div class="col-4">
                <div class="d-flex flex-column w-100 gap-1 dropdown-option">
                  <span class="filter-label">Column</span>
                  <p-dropdown [options]="columns"
                              formControlName="column"
                              placeholder="Select a column"
                              [tooltip]="conditions.at(i).get('column')?.value"
                              tooltipPosition="top"
                              appendTo="body"></p-dropdown>
                </div>
              </div>
              <!-- Value Input -->
              <div class="col-3">
                <div class="d-flex flex-column w-100 gap-1">
                  <span class="filter-label">Value</span>
                  <input class="filter-field"
                         formControlName="value"
                         type="text"
                         style="width: 100%;" />
                </div>
              </div>
              <!-- Operation Dropdown -->
              <div class="col-4">
                <div class="d-flex flex-column w-100 gap-1 dropdown-option">
                  <span class="filter-label">Operation</span>
                  <p-dropdown [options]="operations"
                              formControlName="operation"
                              optionLabel="name"
                              placeholder="Select an operation"
                              [tooltip]="getOperationName(i)"
                              tooltipPosition="top"
                              appendTo="body"></p-dropdown>
                </div>
              </div>
              <!-- Remove Button -->
              <div class="col-1">
                <div class="d-flex align-items-center justify-content-end ht-100">
                  <i class="pi pi-trash mt-4" style="cursor:pointer;" (click)="removeCondition(i)"></i>
                </div>
              </div>
            </div>
            <!-- AND/OR Dropdown, consider how you want to handle the conjunction for each condition -->
            <div class="d-center" *ngIf="1 == 1">
              <div class="d-center dropdown-option" style="margin: 21px 0;width: 250px;height: 33px;">
                <p-divider>
                  <p-dropdown [options]="andOrOptions"
                              formControlName="conjunction"
                              optionLabel="label"
                              placeholder="Select And/Or"></p-dropdown>
                </p-divider>
              </div>
            </div>
          </ng-container>
        </div>
        <!-- Add More Conditions Button -->
        <div class="d-flex" style="place-content: space-between;">
          <div class="btn-box mt-2 mb-2">
            <p-button label="Add More Conditions" (onClick)="addCondition()" icon="pi pi-plus"></p-button>
          </div>
          <div class="btn-box-filter mt-2 mb-2">
            <p-button label="Apply Filters" (onClick)="applyFilters()" icon="pi pi-filter"></p-button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>