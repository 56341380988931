<div class="box-1 pos-sticky" style="display: flex; justify-content: space-between;">
  <p-panel [toggleable]="true" style="width: 100%; margin-bottom: 20px;" expandIcon="pi pi-chevron-up" collapseIcon="pi pi-chevron-down">
    <ng-template pTemplate="header">
      <div class="main-flex-box">
        <div>
          <div class="main-headings">
            <h1 class="text-1">Data Explorer</h1>
            <div class="white-box">
              <div>
                <p class="m-0 db-name">DB Name:</p>
              </div>
              <div>
                <p class="m-0 db-name-desc">{{databaseName}}</p>
              </div>
            </div>
            <div class="white-box">
              <div>
                <p class="m-0 db-name">Server:</p>
              </div>
              <div>
                <p class="m-0 db-name-desc">{{connectionString | slice:0:35}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="search-input-container">
          <div class="rounded-button-filter-white">
            <span class="p-input-icon-left" style="height: 100%;">
              <i class="pi pi-search"></i>
              <input type="text" placeholder="Search Tables" class="search-input w-100" pInputText />

            </span>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="box-1" style="display: flex; justify-content: space-between; flex-wrap: wrap; gap: 15px;">
      <div class="d-flex gap-3">
        <div [ngClass]="isAllTableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('all')">
          <p-button>
            <span>All</span>
            <div class="btn-round">{{all_tableCount}} tables</div>
          </p-button>
        </div>

        <div [ngClass]="is_A_to_E_TableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('a_to_e')">
          <p-button>
            <span>A-E</span>
            <div class="btn-round">{{a_to_e_tableCount}} tables</div>
          </p-button>
        </div>

        <div [ngClass]="is_F_to_J_TableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('f_to_j')">
          <p-button>
            <span>F-J</span>
            <div class="btn-round-gray">{{f_to_j_tableCount}} tables</div>
          </p-button>
        </div>

        <div [ngClass]="is_K_to_O_TableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('k_to_o')">
          <p-button>
            <span>K-O</span>
            <div class="btn-round-gray">{{k_to_o_tableCount}} tables</div>
          </p-button>
        </div>

        <div [ngClass]="is_P_to_T_TableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('p_to_t')">
          <p-button>
            <span>P-T</span>
            <div class="btn-round-gray">{{p_to_t_tableCount}} tables</div>
          </p-button>
        </div>

        <div [ngClass]="is_U_to_Z_TableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'" (click)="showFilteredTables('u_to_z')">
          <p-button>
            <span>U-Z</span>
            <div class="btn-round-gray">{{u_to_z_tableCount}} tables</div>
          </p-button>
        </div>
      </div>


      <div class="d-flex position-set">

        <!-- Save view modal  -->
        <app-save-view-modal *ngIf="isSaveViewModal" [isSaveViewModalVisible]="isSaveViewModal" [viewName]="currentSavedView" (viewNameEnteredEventEmitter)="handleViewNameSaved($event)"></app-save-view-modal>
        <!-- See Saved View -->
        <p-button class="rounded-button-white mr" *ngIf="isViewMode" (click)="seeAllViewTables()" label="View tables" icon="pi pi-table"></p-button>
        <!-- Save View -->
        <p-button class="rounded-button-white mr" *ngIf="isViewMode" (click)="saveView()" label="Save View" icon="pi pi-save"></p-button>
        <!-- (#) Tables in View -->
        <p-button class="rounded-button-white mr" *ngIf="!dataView" (click)="addNewView()" label="({{tablesInViewCount}}) Tables in View" icon="pi pi-filter">
        </p-button>

        <p-button class="rounded-button-white mr" (click)="seeSavedViews()" label="Saved Views" icon="pi pi-list">
        </p-button>
      
      </div>
    </div>
  </p-panel>


</div>

<!-- Mini tables -->
<div *ngIf="!skeleton" class="card box-2">
  <div class="grid" style="overflow-x: auto;">
    <div class="row pb-27">
      <div *ngFor="let table of viewableTables"
           class="col-4 md:col-4 lg:col-4 pb-10">
        <p-panel [toggleable]="true"
                 class="custom-panel"
                 collapseIcon="pi pi-plus"
                 expandIcon="pi pi-minus"
                 [collapsed]="isCollapsed">
          <ng-template pTemplate="header">
            <!-- <span class="icon-container-logo">
              <img alt="data-table-icon" src="../../../../../assets/images/svg/database-icon.svg"
                style="width: 1.5rem" />
            </span> -->
            <div class="card-head-icons">
              <div>
                <span class="title" style="flex-basis:200px">&nbsp; {{table.name}}</span>
              </div>
              <div class="card-head-icons-list">
                <span class="icon-container" *ngIf="!isTableInView(table)" (click)="addTableToView(table)">
                  <i class="pi pi-save hand-cursor" style="font-size: 0.9rem; color: #669BBC; font-weight: 900;"></i>
                </span>
                <span class="icon-container" *ngIf="isViewMode && isTableInView(table)" (click)="removeTableFromView(table)"
                      style="background-color: red;">
                  <i class="pi pi-trash hand-cursor" style="font-size: 0.9rem; color: #669BBC; font-weight: 900;"></i>
                </span>

                <div style="position: relative;">
                  <span class="icon-container" (click)="toggleFilterModal(table)">
                    <i class="pi pi-filter hand-cursor" style="font-size: 0.9rem; color: #669BBC; font-weight: 900;"></i>
                  </span>
                  <div style="position: absolute; z-index: 9;" class="mt-3">
                    <app-filter-modal *ngIf="resultMatch?.name === table?.name && showFilterModal"
                                      [visible]="resultMatch?.name === table?.name"
                                      [columns]="tableColumnsForFilter"
                                      (applyFilterEventEmitter)="applyFilter($event)"></app-filter-modal>
                  </div>
                </div>

                <span class="icon-container" (click)="showTableViewtDialog()">
                  <i class="pi pi-eye hand-cursor" style="font-size: 0.9rem; color: #669BBC; font-weight: 900;"></i>
                </span>
              </div>
            </div>
          </ng-template>

          <div style="overflow-x: auto; max-height:15em"
               #scrollableElement
               (scroll)="onScroll($event, table.name)">
            <table [id]="table.name + '_data'">
              <thead>
                <tr>
                  <th>Audit Type</th>
                  <th>AuditID</th>
                  <th>Modified By</th>
                  <th>Modified Date</th>
                  <th *ngFor="let tableColumn of table.data[0].TableColumns">{{tableColumn.Name}}</th>
                </tr>
              </thead>
              <tr class="bg-evenly" *ngFor="let tableRecord of table.data">
                <td>{{tableRecord.AuditType}}</td>
                <td>{{tableRecord.AuditId}}</td>
                <td> <span [ngClass]="table.data[0].modifiedBy ? 'red-box' : ''">{{tableRecord.ModifiedBy}}</span></td>
                <td>{{tableRecord.ModifiedDate}}</td>
                <td *ngFor="let tableColumn of tableRecord.TableColumns">{{tableColumn.Value}}</td>
              </tr>
            </table>
          </div>
        </p-panel>
      </div>
    </div>
  </div>
</div>




<div  *ngIf="skeleton">
  <div class="card box-2">
    <div class="grid" style="overflow-x: auto;">
      <div class="row pb-27">
        <div class="col-4 md:col-4 lg:col-4 pb-10">
          <p-panel [toggleable]="false"
                   collapseIcon="pi pi-plus"
                   expandIcon="pi pi-minus"
                   [collapsed]="isCollapsed">
        
        
             <ng-template pTemplate="header">
              <app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1],class:'mt-1'}"></app-skeleton>
            </ng-template>
            <div style="overflow-x: auto; max-height:15em"
                 >
              <table>
                <thead>
                  <tr>
                    <th>Audit Type</th>
                    <th>AuditID</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tr class="bg-evenly">
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                </tr>
              </table>
            </div>
          </p-panel>
          
        </div>
        <div class="col-4 md:col-4 lg:col-4 pb-10">
          <p-panel [toggleable]="false"
                   collapseIcon="pi pi-plus"
                   expandIcon="pi pi-minus"
                   [collapsed]="isCollapsed">
        
        
             <ng-template pTemplate="header">
              <app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1],class:'mt-1'}"></app-skeleton>
            </ng-template>
            <div style="overflow-x: auto; max-height:15em"
                 >
              <table>
                <thead>
                  <tr>
                    <th>Audit Type</th>
                    <th>AuditID</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tr class="bg-evenly">
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                </tr>
              </table>
            </div>
          </p-panel>
          
        </div>
        <div class="col-4 md:col-4 lg:col-4 pb-10">
          <p-panel [toggleable]="false"
                   collapseIcon="pi pi-plus"
                   expandIcon="pi pi-minus"
                   [collapsed]="isCollapsed">
        
        
             <ng-template pTemplate="header">
              <app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1],class:'mt-1'}"></app-skeleton>
            </ng-template>
            <div style="overflow-x: auto; max-height:15em"
                 >
              <table>
                <thead>
                  <tr>
                    <th>Audit Type</th>
                    <th>AuditID</th>
                    <th>Modified By</th>
                    <th>Modified Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tr class="bg-evenly">
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                  <td><app-skeleton [skeleton]="{width:'100%',height:'2rem',count:[1,2,3,4,5],class:'mt-1'}"></app-skeleton></td>
                </tr>
              </table>
            </div>
          </p-panel>
          
        </div>
      </div>
    </div>
  </div>
</div>


<div class="d-flex gap-3 collapse-button" *ngIf="viewableTables?.length">
  <div [ngClass]="isAllTableCountSelected ? 'rounded-button-filter' : 'rounded-button-filter-white'">
    <p-button (click)="collapseExpandTables()">
      <i [ngClass]="isCollapsed? 'pi pi-angle-double-down': 'pi pi-angle-double-up'"></i>
      <div class="btn-round">{{isCollapsed? 'Expand all': 'collapse all'}}</div>
    </p-button>
  </div>
</div>

<ng-container *ngIf="visible">
  <app-data-table-view-modal (valueChange)="close($event)"></app-data-table-view-modal>
</ng-container>

 <!-- view table list pop/modal -->
<div class="view-data-list">
  <p-dialog header="({{tablesInViewCount}}) Tables in view" [modal]="true" [(visible)]="dataView">
    <div *ngFor="let table of tablesInView" class="view-data">
      <span class="span">{{table.name}}</span> <i (click)="removeFromTablesInView(table)" class="pi pi-times chip-icon"></i>
    </div>
  </p-dialog>
</div>