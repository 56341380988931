import { Component, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/pages/base/base.component';
import { HeaderService } from 'src/app/services/_app/header/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnDestroy {
  isInViewMode: boolean = false;
  isShowSavedViews: boolean = false;
  savedViews: Array<any> = [];
  private isInViewModeSubscription: Subscription;
  private isShowSavedViewsSubscription: Subscription;
  private savedViewsSubscription: Subscription;
  searchOverlayVisible: boolean = false;
  isCreateAuditDialog: boolean = false;
  sidebarVisible: boolean = false;
  hasSavedViews: boolean = true;
  savedViewsCount: number = 0;
  items: MenuItem[] = [
    {
      label: 'See full data report',
      icon: 'pi pi-eye',
    },
    {
      label: 'Backup DB',
      icon: 'pi pi-cloud-upload',
    },
    {
      label: 'Delete Audit',
      icon: 'pi pi-trash'
    }
  ];

  parentId: string = "search-input-id";

  constructor(private _headerService: HeaderService) {
    super();
    this.isInViewModeSubscription = this._headerService.isInViewMode$.subscribe(isInViewMode => {
      this.isInViewMode = isInViewMode;
    });
    this.isShowSavedViewsSubscription = this._headerService.isShowSavedViews$.subscribe(isShowSavedViewsSubscription => {
      this.isShowSavedViews = isShowSavedViewsSubscription;

      if (this.isShowSavedViews) {
        this.showSavedViews();
      }
      
    });
    this.savedViewsSubscription = this._headerService.savedViews$.subscribe(savedViewsSubscription => {
      console.log("savedViews", savedViewsSubscription);
      this.savedViews = savedViewsSubscription;
      this.savedViews.map((x) => {
        x.TableCount = x.Tables.length;
      });

      this.savedViewsCount = this.savedViews.length;
    });
  }

  searchOnFocus() {
    setTimeout(() => {
      let all = document.getElementsByClassName('p-overlaypanel-content');
      debugger
      console.log("ngDoCheck", all)
      let targetElement = this.getFirstElementWithNoId(all);
      if (targetElement) {
        targetElement.id = this.parentId + '-abc';

        let parent = document.getElementById(this.parentId);
        parent?.appendChild(targetElement);
      }
    }, 200)
  }

  searchOnBlur() {
    let element = document.getElementById('search-input-id-abc');
    debugger
    element?.remove();
  }

  openMenu(menu: any, event: any) {
    menu.toggle(event);
  }

  getFirstElementWithNoId(elements: HTMLCollection): Element | null {
    for (let i = 0; i < elements.length; i++) {
      const element = elements.item(i);
      if (element && !element.id) return element;
    }
    return null;
  }

  openCreateAuditModal(event: any) {
    this.isCreateAuditDialog = true;
  }

  dialogCloseEmitter(event: boolean) {
    this.isCreateAuditDialog = event;
  }

  //temp for view UI
  showSavedViews() {
    this.sidebarVisible = !this.sidebarVisible;
    this.hasSavedViews = !this.hasSavedViews;

    setTimeout(() => {
      this.hasSavedViews = !this.hasSavedViews;
    }, 30)
  }

  loadView(savedView: any) {
    this.sidebarVisible = false;
    this._headerService.loadSavedView(savedView);
  }

  deleteSavedView(savedView: any) {
    this._headerService.deleteSavedView(savedView);
  }

  closeViewMode() {
    //this.isInViewMode = false;
    this._headerService.updateIsInViewMode(false);
  }

  ngOnDestroy(): void {
    this.isInViewModeSubscription.unsubscribe();
    this.isShowSavedViewsSubscription.unsubscribe();
    this.savedViewsSubscription.unsubscribe();
  }
}
