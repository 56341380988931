import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAuditDto } from '../../../../models/IAuditDto';
import { IAuditLighthouseDbConnectionDto } from '../../../../models/IAuditLighthouseDbConnectionDto';
import { IAuditLogEntryReferenceDto } from '../../../../models/IAuditLogEntryReferenceDto';
import { IAuditLogEntryRollupDto } from '../../../../models/IAuditLogEntryRollupDto';
import { IAuditLogEntryTimelineDto } from '../../../../models/IAuditLogEntryTimelineDto';
import { LighthouseDashboardService } from '../../../../services/lighthouse-dashbaord/lighthouse-dashboard.service';
import { BaseComponent } from '../../../base/base.component';

@Component({
  selector: 'lighthouse-dashboard',
  templateUrl: './lighthouse-dashboard.component.html',
  styleUrls: ['./lighthouse-dashboard.component.css']
})
export class LighthouseDashboardComponent extends BaseComponent {

  all: boolean = false;
  public isPreviewDataSelected: boolean = false;
  skeleton:boolean = true;

  constructor(private lighthouseDashboardService: LighthouseDashboardService, private router: Router) {
    super();
  }

  override async ngOnInit() {
    await this.getLogEntriesRollupAsync();
    let a = this.Audit;
    this.databaseName = a.DatabaseName;
  }

  public data: any[] = [];
  public data2: any[] = [];
  public data3: any[] = [];
  public databaseName: string = '...';
  public isGridShow: boolean = true;
  public tableName: string = '';

  public gotoDataExplorer() {
    this.router.navigate(['/lighthouse-data-explorer']);
  }

  private async getLogEntriesTimelineAsync() {
    let a: IAuditDto = this.Audit;

    await this.lighthouseDashboardService.getLogEntriesTimelineAsync(a.Id).then((x: Array<Array<IAuditLogEntryTimelineDto>>) => {
      this.data.map((a) => {
        let tableResults = x.filter((b) => b[0].SourceAuditTable === a.tableName);
        tableResults.map((b) => {
          b.filter((c) => {
            switch (c.AuditType) {
              case 'I':
                a.insertions = c.AuditTypeCount;
                break;
              case 'U':
                a.updates = c.AuditTypeCount;
                break;
              case 'D':
                a.deletions = c.AuditTypeCount;
                break;
            }
          });
        });
      });
      this.skeleton = false;
    }).catch((err: any) => {
      console.log(err);
      this.skeleton = false;
    });
  }

  private async getLogEntriesRollupAsync() {
    let a: IAuditDto = this.Audit;

    await this.lighthouseDashboardService.getLogEntriesRollupAsync(a.Id).then(async (x: Array<IAuditLogEntryRollupDto>) => {
      x.map((a: any) => {
        this.data.push({
          tableName: a.SourceAuditTable,
          totalEvents: a.TableRecordCount,
          lastEvent: a.LastUpdated,
          insertions: 0,
          updates: 0,
          deletions: 0,
          selected: false
        });
      });

      await this.getLogEntriesTimelineAsync();
    }).catch((err: any) => {
      console.log(err);
    });
  }

  private async getLogEntriesAsync() {
    let _: IAuditLighthouseDbConnectionDto = {} as IAuditLighthouseDbConnectionDto;
    _.ConnectionString = 'Server=localhost;Initial Catalog=homeazzon-local;Integrated Security=True;TrustServerCertificate=True;Connection Timeout=30;';
    _.AuditDatabaseName = 'SampleDB_Audit';

    await this.lighthouseDashboardService.getLogEntriesAsync(_).then((x: Array<IAuditLogEntryRollupDto>) => {
      x.map((a) => {
        this.data.push({
          tableName: a.SourceAuditTable,
          totalEvents: a.TableRecordCount,
          lastEvent: a.LastUpdated,
          insertions: 0,
          updates: 0,
          deletions: 0,
          selected: false
        });
      });
    }).catch((err: any) => {
      console.log(err);
    });
  }



  girdData() {
    this.isGridShow = true;
  }

  girdDataEmpty() {
    this.isGridShow = false;
  }

  close(event: boolean) {
    this.isPreviewDataSelected = false;
  }

  async showTableData(tableName: string) {
    this.PreviewDataTableName = tableName;
    this.isPreviewDataSelected = true;
    //let a = this.Audit;

    //await this.lighthouseDashboardService.getAuditTablePreviewDataAsync(a.Id, tableName).then((x: Array<IAuditLogEntryReferenceDto>) => {
    //  x.map((z) => {
    //    this.data.push(z);
    //    this.isPreviewDataSelected = true;
    //  });
    //}).catch((err: any) => {
    //  console.log(err);
    //});

  }


}
