import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class AuditProfileService extends baseService {

  constructor(http: HttpClient) {
    super(http);
  }

  private apiRoute: string = '';

  async getAuditProfiles() {
    this.apiRoute = 'auditprofile';
    return this.get(`/${this.apiRoute}`).toPromise();
  }

  async deleteAuditProfile(id: string) {
    this.apiRoute = 'auditprofile';
    return this.delete(`/${this.apiRoute}/${id}`).toPromise();
  }
}
