import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-save-view-modal',
  templateUrl: './save-view-modal.component.html',
  styleUrls: ['./save-view-modal.component.css']
})
export class SaveViewModalComponent implements OnInit {

  @Input() isSaveViewModalVisible!: any;
  @Input() viewName!: string;

  @Output() viewNameEnteredEventEmitter = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
    this.isSaveViewModalVisible;
  }

  saveView() {
    if (this.viewName == undefined || this.viewName == '') {
      alert('Please enter view name');
      return;
    }

    this.viewNameEnteredEventEmitter.emit(this.viewName);
  }
}
