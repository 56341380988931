
<div class="w-100 d-flex flex-row">
  <section class="side-bar">
    <div class="logo">
      <img src="../../assets/images/png/logo.png" alt="Logo" class="logo-image top-left">
    </div>

    <div class="main-image">
      <img class="w-3" src="assets/images/svg/sidebar-placeholder.svg" alt="Main Image">
    </div>
  </section>

  <div class="w-100 sign-up">
    <div class="w-100">
      <div class="right-column">
        <!-- Image at the top -->
        <div class="right-top">
          <div class="align-right">
            <h2 class="text-1 mb-32">Sign up/Login</h2>
            <span class="mb-32">Sign up or Login with Microsoft</span>
            <hr class="hr" />
          </div>
          <button (click)="Login()" mat-flat-button class="primary mb-32">Continue</button>
          <span>This site is protected by Microsoft Privacy Policy.</span>
        </div>
      </div>
    </div>
  </div>
</div>
