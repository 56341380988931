import { Component } from '@angular/core';

import {EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.css']
})
export class OrderModalComponent  implements OnInit {
  @Output() valueChange = new EventEmitter<boolean>();

  productVisible: boolean = false;
  products!: Product[];
  selectedProducts!: Product;
  showFilterModal:boolean = true;

  girdShow: boolean = true;
  girdData() {
    this.girdShow = true;
  }
  girdDataEmpty() {
    this.girdShow = false;
  }

  ngOnInit() {
    this.productVisible = true;
  }


  onDialogHide() {
    this.valueChange.emit(true);
  }

}

export interface Product {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus?: string;
  category?: string;
  image?: string;
  rating?: number;
}

