import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authReq = req;

    let id = localStorage.getItem("ssk_id_token") ?? '';

    if (id === undefined
      || id === null
      || id === '') {
      alert('missing token');
      throw new Error('missing token');
    }

    if (authReq.url.includes('Document')) {
      authReq = req.clone({ headers: new HttpHeaders({ 'Authorization': `Bearer ${id}` }) });
    } else {
      authReq = req.clone({
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${id}`
        })
      });
    }

    return next.handle(authReq);
  }
}
