<div class="createAudit">
  <p-dialog header="Header" (onHide)="dialogClose()" [modal]="true" [(visible)]="productlistVisible"
            [style]="{width: '35.5rem'}">
    <ng-template pTemplate="header">
      <div class="d-flex align-items-center" [style]="{paddingBottom: '0px'}">
        <div class="left-border left-border-bg"></div>
        <div class="d-flex-d-column margin-left-5">
          <span class="user-title-text"
                *ngIf="isViewAuditLog">Audit Log</span>
          <span class="user-title-text"
                *ngIf="!isStartedAuditProcess">Create Audit</span>
          <span class="user-title-text"
                *ngIf="isStartedAuditProcess && isCompletedAuditProcess">Audit Created Successfully</span>
        </div>
      </div>
    </ng-template>
    <p-divider></p-divider>

    <div *ngIf="!isStartedAuditProcess">
      <p class="para-text">
        Create & customize audits seamlessly, ensuring data security and compliance with just a few clicks.
      </p>
      <p-divider></p-divider>
      <div class="input-box">
        <label class="mb-3 label-bold"> Connection String</label>
        <span class="p-input-icon-right input-container">
          <i class="pi status-validate"
             *ngIf="!isConnectionStringValidated"
             style="cursor:pointer !important;"
             (click)="validateConnectionString()">
            Validate
            <i class="pi pi-bolt check-icon"></i>
          </i>
          <i class="pi status-validate" *ngIf="isConnectionStringValidated">
            Validated!
            <i class="pi pi-check check-icon"></i>
          </i>
          <input class="input-text"
                 type="text"
                 pInputText
                 placeholder="Insert a connection string"
                 [(ngModel)]="connectionString">
          <p-progressSpinner class="spinner-loader"
                             [style]="{width: '25px', height: '25px'}"
                             *ngIf="isValidatingConnectionString"></p-progressSpinner>
        </span>
      </div>
      <div class="input-box">
        <label class="mb-3" [ngClass]="isConnectionStringValidated ? 'label-bold' : 'label-regular'">
          Select Database to Audit
        </label>
        <span class="p-input-icon-right input-container">
          <p-dropdown (onChange)="selectDatabaseToAudit($event)"
                      [options]="databases"
                      optionLabel="name"
                      placeholder="Please Select a DB"
                      [disabled]="!isConnectionStringValidated"></p-dropdown>
        </span>
      </div>
      <p-divider></p-divider>
      <div class="input-dropdown-with-capsule-values">
        <div class="input-box">
          <label class="mb-3" [ngClass]="isDatabaseSelected ? 'label-bold' : 'label-regular'">
            <!-- Exclude tables (don't audit these) -->
            Exclude tables
          </label><br>
          <span class="chip-container" *ngIf="excludedTables.length > 0">
            <i class="pi input-chip" *ngFor="let selectedItem of excludedTables">
              {{selectedItem?.name}}
              <i (click)="removeFromExcludeList(selectedItem?.name)"
                 style="cursor:pointer;"
                 class="pi pi-times chip-icon"></i>
            </i>
          </span>
          <span class="p-input-icon-right input-container">
            <p-dropdown (onChange)="selectDatabaseToExclude($event)"
                        [options]="databaseTables"
                        optionLabel="name"
                        placeholder="Select tables to exclude"></p-dropdown>
          </span>
        </div>
      </div>
    </div>
    <div class="progress-container">
      <!-- Created Successfully isStartedAuditProcess && isCompletedAuditProcess -->
      <div *ngIf="isStartedAuditProcess && isCompletedAuditProcess && !isViewAuditLog" class="sucess-box">
        <div><img src="assets/images/svg/rounded-check.svg" alt="" class="search-user-avatar"></div>
        <div>
          <p class="success-text">Created successfully!</p>
        </div>
        <div class="btn-box">
          <p-button [loading]="false"
                    loadingIcon="pi pi-sun"
                    class="filled-btn"
                    label="continue"
                    (onClick)="dialogClose()"
                    severity="secondary"></p-button>
          <p-button [loading]="false"
                    loadingIcon="pi pi-sun"
                    class="outlined-btn"
                    label="View Audit Log"
                    severity="secondary"
                    (onClick)="viewAuditLog()"></p-button>
        </div>
      </div>
      <!-- Audit Process Overview -->
      <section *ngIf="isStartedAuditProcess && !isCompletedAuditProcess">
        <div class="tables-progress-box">
          <div class="progress-table d-flex align-items-center">
            <div class="progress-item complete">
              <i class="pi pi-fw pi-database"></i>
              <i class="pi pi-fw pi-search"></i>
            </div>
            <div><span class="progress-text">Creating Audit Database</span></div>
          </div>
          <div class="progress-status">
            <div class="complete-status"> <i class="pi pi-fw pi-check"></i></div>
          </div>
        </div>
        <div class="tables-progress-box">
          <div class="progress-table d-flex align-items-center">
            <div class="progress-item"
                 [ngClass]="isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed ? 'complete' : ''">
              <i class="pi pi-fw pi-clone"></i>
            </div>
            <div><span class="progress-text">Copy users from source to audit</span></div>
          </div>
          <div class="progress-status">
            <div class="genral-status"
                 *ngIf="!isCopyingUsersFromSourceDatabaseToAuditDatabase && !isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed">
              In queue
            </div>
            <div class="genral-status" *ngIf="isCopyingUsersFromSourceDatabaseToAuditDatabase"> Processing...</div>
            <div class="complete-status" *ngIf="isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed">
              <i class="pi pi-fw pi-check"></i>
            </div>
          </div>
        </div>
        <div class="tables-progress-box">
          <div class="progress-table d-flex align-items-center">
            <div class="progress-item" [ngClass]="isCreatingAuditLighthouse_Completed ? 'complete' : ''">
              <i class="pi pi-fw pi-database"></i>
              <i class="pi pi-fw pi-search-plus"></i>
            </div>
            <div><span class="progress-text">Audit Lighthouse Database</span></div>
          </div>
          <div class="progress-status">
            <div class="genral-status" *ngIf="!isCreatingAuditLighthouse && !isCreatingAuditLighthouse_Completed">
              In queue
            </div>
            <div class="genral-status" *ngIf="isCreatingAuditLighthouse"> Processing...</div>
            <div class="complete-status" *ngIf="isCreatingAuditLighthouse_Completed">
              <i class="pi pi-fw pi-check"></i>
            </div>
          </div>
        </div>
        <div class="tables-progress-box">
          <div class="progress-table d-flex align-items-center">
            <div class="progress-item"
                 [ngClass]="isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed ? 'complete' : ''">
              <i class="pi pi-fw pi-clone"></i>
            </div>
            <div><span class="progress-text">Copy users from audit to Lighthouse audit</span></div>
          </div>
          <div class="progress-status">
            <div class="genral-status"
                 *ngIf="!isCopyingUsersFromAuditDatabaseToLighthouseDatabase && !isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed">
              In queue
            </div>
            <div class="genral-status" *ngIf="isCopyingUsersFromAuditDatabaseToLighthouseDatabase"> Processing...</div>
            <div class="complete-status" *ngIf="isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed">
              <i class="pi pi-fw pi-check"></i>
            </div>
          </div>
        </div>
        <p-divider></p-divider>
        <!-- Progress Bar isAuditInProcess -->
        <div *ngIf="isStartedAuditProcess && !isCompletedAuditProcess">
          <div class="pleaseWaitMsg px-5 py-5">
            <p-progressBar [value]="response"></p-progressBar>
          </div>
          <div class="btn-box justify-content-center">
            <p-button class="outlined-btn bg-color"
                      [loading]="true"
                      label="Create Audits... 0"></p-button>
          </div>
        </div>
      </section>
    </div>

    <!-- Bottom buttons -->
    <div class="btn-box" *ngIf="!isStartedAuditProcess">
      <!-- Create button -->
      <p-button [loading]="isValidatingConnectionString || isGettingDatabases || isGettingDatabaseTables"
                class="filled-btn"
                label="Create"
                (onClick)="startAuditLighthouseProcess()" severity="secondary"></p-button>

      <!-- Add reset & Cancel button code here  -->
      <p-button class="outlined-btn reset-btn"
                [loading]="false"
                label="Reset"></p-button>
    </div>
  </p-dialog>
</div>
