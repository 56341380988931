import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAuditLogEntryReferenceDto } from '../../../../../../models/IAuditLogEntryReferenceDto';
import { LighthouseDashboardService } from '../../../../../../services/lighthouse-dashbaord/lighthouse-dashboard.service';
import { BaseComponent } from '../../../../../base/base.component';

@Component({
  selector: 'app-preview-data-modal',
  templateUrl: './preview-data-modal.component.html',
  styleUrls: ['./preview-data-modal.component.css']
})
export class PreviewDataModalComponent extends BaseComponent {
  constructor(private lighthouseDashboardService: LighthouseDashboardService) {
    super();
    let _ = this.Audit;
    this.tableName = this.PreviewDataTableName;
  }

  @Output() valueChange = new EventEmitter<boolean>();
  @Input() data: any = [];
  public tableData: Array<any> = [];
  public tableHeaders: Array<any> = [];
  skeleton:boolean = true;
  bodyskeleton:boolean = true;

  previewDataVisible: boolean = true;
  products!: Product[];
  public selectedLogEntryReference!: IAuditLogEntryReferenceDto;
  public showFilterModal: boolean = false;

  public gridShow: boolean = true;
  public tableName: string = '';
  public totalNumberOfEvents: number = 0;
  public logEntryReferences: Array<IAuditLogEntryReferenceDto> = [];

  getPreviewDataProxy() {
    return Promise.resolve(this.getPreviewDataService().slice(0, 4));
  }

  getPreviewDataService() {
    return this.tableData;
  }

  override async ngOnInit() {
    //await this.getFakeData();
    await this.getPreviewData();
  }

  onDialogHide() {
    this.valueChange.emit(true);
  }

  private async getFakeData() {
    this.previewDataVisible = true;

    let headers: Array<any> = ['AuditType', 'AuditId', 'ModifiedBy', 'ModifiedDate'];

    for (var i = 0; i < 30; i++) {

      let b: Array<string> = [];

      for (var j = 0; j < 10; j++) {
        if (i == 0) {
          headers.push(`Column ${j}`);
        } else {
          b.push(`Value: row # ${i} + column: ${j}`);
        }
      }

      if (i === 0) {
        this.tableHeaders = headers;
        continue;
      }

      let a: any = {
        AuditType: 'I',
        AuditId: i,
        ModifiedBy: 'ModifiedBy',
        ModifiedDate: '11/05/2023',
        TableColumns: b
      }; // IAuditLogEntryReferenceDto // becuse when I get the
      // data back from the API it is PascalCase but when I console.log
      // it shows as camelCase.

      this.logEntryReferences.push(a);
    }

    console.log(this.logEntryReferences);
    this.totalNumberOfEvents = this.logEntryReferences.length;
  }

  private async getPreviewData() {
    let _1 = this.Audit;
    let _2 = this.PreviewDataTableName;
    this.skeleton = false;
    await this.lighthouseDashboardService.getAuditTablePreviewDataAsync(_1.Id, _2).then((x: Array<IAuditLogEntryReferenceDto>) => {
      x.map((z) => {
        this.data.push(z);
      });

      this.hydrateTableData();

      this.totalNumberOfEvents = this.logEntryReferences.length;

      this.previewDataVisible = true;
      this.bodyskeleton = false;
    }).catch((err: any) => {
      console.log(err);
      this.bodyskeleton = false;
    });
  }

  private getHeaders() {
    let headers2: Array<any> = [];
    let ss: any = [];

    let _: Array<any> = this.data;
    let ctr: number = 0;

    _.forEach((i) => {
      if (ctr === 0) {
        Object.keys(i).forEach(key => {
          let propertyName = key;
          if (propertyName === 'TableColumns') {
            let columns = i[key];
            columns.forEach((x: any) => {
              headers2.push(x.Name);
            });
            ss.push(columns);
          } else {
            headers2.push(propertyName);
          }
        });
      }
      ctr += 1;
    });

    return headers2;
  }

  private hydrateTableData() {
    this.tableHeaders = this.getHeaders();
    this.logEntryReferences.push();

    for (var i = 0; i < this.data.length; i++) {
    //for (var i = 0; i < 50; i++) {

      let b: Array<string> = [];

      let a: any = {
        AuditType: this.data[i].AuditType,
        AuditId: this.data[i].AuditId,
        ModifiedBy: this.data[i].ModifiedBy,
        ModifiedDate: this.data[i].ModifiedDate,
        TableColumns: this.data[i].TableColumns
      }; // IAuditLogEntryReferenceDto // becuse when I get the
      // data back from the API it is PascalCase but when I console.log
      // it shows as camelCase.

      this.logEntryReferences.push(a);
    }
  }
}

export interface Product {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus?: string;
  category?: string;
  image?: string;
  rating?: number;
}
