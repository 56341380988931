//import { Component, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNaveComponent {
  items: MenuItem[] = [];
  toggle: boolean = false;

  navItems = [
    {
      icon: 'pi pi-fw pi-home', routerLink: '/dashboard',
      submenu: null
    },
    {
       top:'pi-database-25',
      icon: 'pi pi-fw pi-database', routerLink: '/dashboard-light-house',
      submenu: [{
        routerLink: '/dashboard-drag',
        label: 'Create Audit',
      },
      {
        label: 'Lighthouse Dashboard',
        icon: 'pi pi-fw pi-menu',
        routerLink: '/lighthouse-dashboard'
      },
      {
        routerLink: '/data-table',
        label: 'Dynamic Query',
      },
      {
        routerLink: '/dashboard-light-house-accesslogs',
        label: 'API Integration',

      }]
    },
    {
      top:'pi-shield-25',
      icon: 'pi pi-fw pi-shield', routerLink: '/data-table',
      submenu: [{
        routerLink: '/dashboard-drag',
        label: 'Compliance Status'
      },
      {
        routerLink: '/data-table',
        label: 'Data Encryption',
      },
      {
        routerLink: '/dashboard-light-house-accesslogs',
        label: 'Security Audits',

      }]
    },
    {
      top:'pi-users-30',
      icon: 'pi pi-fw pi-users', routerLink: '/dashboard-light-house-accesslogs',
      submenu: [{
        routerLink: '/dashboard-drag',
        label: 'Automated User Management'
      },
      {
        routerLink: '/data-table',
        label: 'Manage Triggers',
      }]
    },
    {
      icon: 'pi pi-fw pi-server', routerLink: '/dashboard-pricing-and-plans',
      submenu: null
    },
    {
      icon: 'pi pi-fw pi-tag', routerLink: '/tag',
      submenu: null
    },
  ];
  selected: string = 'pi-home';
  selectedSub: string= '';

  constructor(private router: Router) {
  }
  ngOnInit() {
    this.items = [
      {
        label: 'Dashboard',
        icon: 'pi pi-home',
        routerLink: '/dashboard'
      },
      {
        label: 'Auditing Setup',
        icon: 'pi pi-database',
        items: [
          {
            label: 'Create Audit',
          },
          {
            label: 'Lighthouse Dashboard',
            icon: 'pi pi-fw pi-menu',
            routerLink: '/lighthouse-dashboard'
          },
          {
            label: 'Dynamic Query'
          },
          {
            label: 'API Intergration',

          }
        ]
      },
      {
        label: 'Compliance & Security',
        icon: 'pi pi-shield',
        items: [
          {
            label: 'Compliance Status',

          },
          {
            label: 'Data Encryption',

          },
          {
            label: 'Secuity Audits',

          }
        ]
      },
      {
        label: 'User Mngmt for Triggers',
        icon: 'pi pi-users',
        items: [
          {
            label: 'Automated User Management',

          },
          {
            label: 'Manage Triggers',

          },
        ]
      },
      {
        label: 'Connection to Local DB’s',
        icon: 'pi pi-fw pi-server',
      },
      {
        label: 'Pricing & Plans',
        icon: 'pi pi-tag',
        routerLink: '/dashboard-pricing-and-plans'
      }
    ];

  }




  Check(event: string) { }


  selectedItem: any;

  showSubMenu(item: any, index: number) {
    this.selectedItem = item;
    this.selectedItem.index = index;
  }

  hideSubMenu() {
    this.selectedItem = null;
  }


  checkSelected(active: string, url?: string) {
    this.selected = active;
    this.router.navigate([url]);
  }

  subSelected(active: string, url?: string) {
    this.selectedSub = active;
    this.router.navigate([url]);
  }

}
