import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAuditDto } from '../../models/IAuditDto';
import { IAuditLighthouseDto } from '../../models/IAuditLighthouseDto';
import { IUserRoleReplicationDto } from '../../models/IUserRoleReplicationDto';
import { IUtilityDto } from '../../models/IUtility';
import { BaseComponent } from '../../pages/base/base.component';
import { AuditInfrastructureService } from '../../services/audit-infrastructure/audit-infrastructure.service';
import { AuditLighthouseService } from '../../services/audit-lighthouse/audit-lighthouse.service';
import { AuditUtilityService } from '../../services/audit-utility/audit-utility.service';

@Component({
  selector: 'app-create-audit-modal',
  templateUrl: './create-audit-modal.component.html',
  styleUrls: ['./create-audit-modal.component.css']
})
export class CreateAuditModalComponent extends BaseComponent {

  @Input() auditmetricsDetails!: any

  @Output() dialogCloseEmitter = new EventEmitter<boolean>();

  productlistVisible!: boolean;
  loading!: boolean;
  loading1: boolean = true;
  databases!: { name: string; code: string; }[];
  databaseTables!: { name: string; code: string; }[];
  excludedTables: any[] = [];
  selectedDB: string = '';
  fileName: any;
  isfileZone: boolean = false;
  istemplate: boolean = true;
  waitloading: boolean = false;
  isAuditInProgress: boolean = false;

  isSelecteTab: boolean = false
  isConnectionStringValidated: boolean = false;
  isDatabaseSelected: boolean = false;
  isAuditFormComplete: boolean = false;
  connectionString: string = '';
  status: string = 'To begin, validate your connection string.';

  isStartedAuditProcess: boolean = false;
  isCompletedAuditProcess: boolean = false;
  isCreatingAuditDatabase: boolean = false;
  isCopyingUsersFromSourceDatabaseToAuditDatabase: boolean = false;
  isCreatingAuditLighthouse: boolean = false;
  isCopyingUsersFromAuditDatabaseToLighthouseDatabase: boolean = false;
  isCreatingAuditDatabase_Completed: boolean = false;
  isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed: boolean = false;
  isCreatingAuditLighthouse_Completed: boolean = false;
  isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed: boolean = false;
  isValidatingConnectionString: boolean = false;

  nextTempForUI_View: number = 0; // temp.. bit for view UI Screens
  response!: number;

  constructor(private auditUtilityService: AuditUtilityService,
    private auditInfrastructureService: AuditInfrastructureService,
    private auditLighthouseService: AuditLighthouseService) {
    super();
  }

  override ngOnInit(): void {
    this.productlistVisible = true;
    this.databases = [
      { name: '', code: '' }
    ];
  }

  screenDemo() {
    this.isCreatingAuditDatabase = false;
    this.isCreatingAuditDatabase_Completed = true;
    this.isCopyingUsersFromSourceDatabaseToAuditDatabase = true;
    setTimeout(() => {

      setTimeout(() => {
        this.isCopyingUsersFromSourceDatabaseToAuditDatabase = false;
        this.isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed = true;
        this.isCreatingAuditLighthouse = true;
        setTimeout(() => {
          this.isCreatingAuditLighthouse = false;
          this.isCreatingAuditLighthouse_Completed = true;
          this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase = true;
          setTimeout(() => {
            this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase = false;
            this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed = true;
            this.isCompletedAuditProcess = true;
            this.waitloading = false;
            this.isAuditInProgress = false;

            this.isAuditFormComplete = true;
          }, 3000);
        }, 3000);
      }, 3000);
    }, 3000);

  }

  async startAuditLighthouseProcess() {
    this.isStartedAuditProcess = true;
    this.isCompletedAuditProcess = false;
    this.status = 'Processing...';
    this.istemplate = false;
    this.loading = true;
    this.waitloading = true;

    let _: IAuditDto = {} as IAuditDto;
    _.ConnectionString = this.connectionString;
    _.DatabaseName = this.selectedDB;
    _.ExcludeTables = [];
    this.excludedTables.map((x) => {
      _.ExcludeTables.push(x.name);
    });

    this.isAuditInProgress = true;
    this.isCreatingAuditDatabase = true;

    //this.screenDemo();
    //this.setIncrementalTimeout();
    //return;
    await this.auditInfrastructureService.createAuditDatabase(_).then(async (x: IAuditDto) => {

      let a = this.Audit;
      a = x;
      this.Audit = a;

      this.isCreatingAuditDatabase = false;
      this.isCreatingAuditDatabase_Completed = true;
      this.isCopyingUsersFromSourceDatabaseToAuditDatabase = true;

      let r: IUserRoleReplicationDto = {} as IUserRoleReplicationDto;
      r.ConnectionString = this.connectionString;
      r.SourceDatabaseName = this.selectedDB;
      r.TargetDatabaseName = `${this.selectedDB}_Audit`;

      //this.setIncrementalTimeout();

      await this.auditUtilityService.replicateUsersAndRoles(r).then(async (y: any) => {
        this.isCopyingUsersFromSourceDatabaseToAuditDatabase = false;
        this.isCopyingUsersFromSourceDatabaseToAuditDatabase_Completed = true;
        this.isCreatingAuditLighthouse = true;

        let l: IAuditLighthouseDto = {} as IAuditLighthouseDto;
        l.ConnectionString = this.connectionString;
        l.AuditDatabaseName = `${this.selectedDB}_Audit`;

        //this.setIncrementalTimeout();

        await this.auditLighthouseService.createAuditLighthouseDatabase(l).then(async (y: any) => {
          this.isCreatingAuditLighthouse = false;
          this.isCreatingAuditLighthouse_Completed = true;
          this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase = true;

          r.SourceDatabaseName = l.AuditDatabaseName;
          r.TargetDatabaseName = `${l.AuditDatabaseName}_Lighthouse`;

          //this.setIncrementalTimeout();

          await this.auditUtilityService.replicateUsersAndRoles(r).then((z: any) => {
            this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase = false;
            this.isCopyingUsersFromAuditDatabaseToLighthouseDatabase_Completed = true;
            this.isCompletedAuditProcess = true;
            this.waitloading = false;
            this.isAuditInProgress = false;

            this.isAuditFormComplete = true;
          }).catch((err) => {

          });
        }).catch((err) => {

        });
      }).catch((err) => {

      });
      //x.map((a) => {
      //  this.data.push({
      //    tableName: a.SourceAuditTable,
      //    totalEvents: a.TableRecordCount,
      //    lastEvent: a.LastUpdated,
      //    insertions: 0,
      //    updates: 0,
      //    deletions: 0,
      //    selected: false
      //  });
      //});
    }).catch((err: any) => {
      console.log(err);
    });

    this.loading = false;
  }

  isViewAuditLog: boolean = false;

  viewAuditLog() {
    this.isViewAuditLog = true;
  }
  selectDatabaseToExclude(item: any) {
    this.excludedTables.push(item?.value);
  }

  removeFromExcludeList(itemName: string) {
    let _ = this.excludedTables.filter(x => x.name !== itemName);
    this.excludedTables = [];
    this.excludedTables = _;
  }

  isGettingDatabases: boolean = false;
  isGettingDatabaseTables: boolean = false;

  async selectDatabaseToAudit(item: any) {
    this.selectedDB = item?.value.name;
    this.isfileZone = true;

    if (item !== undefined && item !== null) {
      let _ = this.Audit;

      _.DatabaseName = item.Value;
      this.Audit = _;

      this.isDatabaseSelected = true;
      this.isGettingDatabaseTables = true;
      await this.getDatabaseTables();
    }

  }

  onBasicUploadAuto(uploaditem: any) { }

  addExcludeTables(res: any) {
    this.isSelecteTab = true;
    this.isfileZone = false;
  }

  dialogClose() {
    this.dialogClosedEmitter();
    this.excludedTables
    this.excludedTables = [];
  }

  dialogClosedEmitter() {
    this.dialogCloseEmitter.emit(false);
  }

  async validateConnectionString() {
    this.isValidatingConnectionString = true;
    let _: IUtilityDto = {} as IUtilityDto;
    _.ConnectionString = this.connectionString;

    await this.auditUtilityService.validateConnectionString(_).then(async (x: boolean) => {
      this.isConnectionStringValidated = x;
      this.isValidatingConnectionString = false;

      if (x) {
        let a: IAuditDto = {} as IAuditDto;
        a.ConnectionString = this.connectionString;

        this.Audit = a;
        this.status = 'Connection to database succeeded!';

        await this.getDatabases();
      } else {
        this.status = 'Connection to database failed!';
      }
    }).catch((err: any) => {
      console.log(err);
    });
  }

  async getDatabases() {
    this.isGettingDatabases = true;
    this.status = 'Getting databases...';
    let _: IUtilityDto = {} as IUtilityDto;
    _.ConnectionString = this.connectionString;
    this.databases = [];
    await this.auditUtilityService.getDatabases(_).then((x: Array<string>) => {
      this.isGettingDatabases = false;
      x.map((a) => {
        this.databases.push({
          code: a,
          name: a
        });
      });

      if (x) {
        let a: IAuditDto = {} as IAuditDto;
        a.ConnectionString = this.connectionString;

        this.Audit = a;

        this.status = 'Finished getting databases!';
        //this.status = 'Connection to Database succeeded!';
      } else {
        this.status = 'Connection to database failed!';
      }
    }).catch((err: any) => {
      console.log(err);
    });
  }

  async getDatabaseTables() {
    let _: IUtilityDto = {} as IUtilityDto;
    _.ConnectionString = this.connectionString;
    _.DatabaseName = this.selectedDB;

    this.databaseTables = [];

    await this.auditUtilityService.getDatabaseTables(_).then((x: Array<string>) => {
      this.isGettingDatabaseTables = false;
      x.map((a) => {
        this.databaseTables.push({
          code: a,
          name: a
        });
      });

    }).catch((err: any) => {
      console.log(err);
    });
  }

  // tem fun for progress
  setIncrementalTimeout() {
    for (let i = 1; i <= 1000000; i++) {
      setTimeout(() => this.response = i, i * 100);
    }
  }
}
