import { Component, OnInit } from '@angular/core';
import { IAuditDto } from '../../models/IAuditDto';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  // Audit
  get Audit(): IAuditDto {
    let a1: any = localStorage.getItem('ssk_Audit');

    if (a1 == 'undefined'
      || a1 == undefined
      || a1 == null) {
      return {} as IAuditDto;
    }

    let a: IAuditDto = JSON.parse(a1);

    if (a == undefined || a == null) {
      return {} as IAuditDto;
    }

    return a;
  }

  set Audit(value: IAuditDto) {
    console.log('AuditDto', value);
    localStorage.setItem('ssk_Audit', JSON.stringify(value));
  }

  // PreviewDataTableName
  get PreviewDataTableName(): string {
    let a1: any = localStorage.getItem('ssk_PreviewDataTableName');

    if (a1 == 'undefined'
      || a1 == undefined
      || a1 == null
      || a1 == '') {
      return 'NOT SET';
    }

    return a1;
  }

  set PreviewDataTableName(value: string) {
    localStorage.setItem('ssk_PreviewDataTableName', value);
  }
}
