<div class="flex-container">
  <div class="item-4">
    <div class="{{searchOverlayVisible ? 'search-wraper':''}}">
      <div class="{{searchOverlayVisible? 'pad-all':''}}">
        <span id="search-input-id" class="z-index-100 p-input-icon-left"
              style="position: relative;display: flex;flex-direction: column;"
              [ngClass]="{'p-input-icon-right': searchOverlayVisible}">
          <i class="pi"
             [ngClass]="{'pi-arrow-left': searchOverlayVisible, 'pi-search': !searchOverlayVisible}"></i>
          <input type="text" (click)="searchOverlayVisible = true" class="search-input w-100"
                 [ngClass]="{'search-active': searchOverlayVisible}" pInputText
                 placeholder="Search or type a command" (focus)='searchOnFocus()' (blur)="searchOnBlur()" />
          <i *ngIf="searchOverlayVisible" (click)="searchOverlayVisible = false" role="button"
             class="pi pi-times-circle"></i>
        </span>
      </div>
      <span *ngIf="searchOverlayVisible" class="search-overlay">
        <div class="search-banner">
          <div class="notification-header mb-2">
            <h3 class="search-dd-title">Recent search</h3>
          </div>
          <div class="notification-container mb-4">
            <div class="notification-media">
              <img width="48" height="48"
                   src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                   alt="" class="search-user-avatar mr-2">
              <div class="notification-content">
                <p class="notification-text">
                  <span class="text-style-13">For reviewing database activity</span><br />
                  <span class="text-style-15">Audit Log Reports</span>
                </p>
              </div>
            </div>
            <div class="status-nd-timer">
              <i class="pi pi-times"></i>
            </div>
          </div>
          <div class="notification-container mb-4">
            <div class="notification-media">
              <img width="48" height="48"
                   src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                   alt="" class="search-user-avatar mr-2">
              <div class="notification-content">
                <p class="notification-text">
                  <span class="text-style-13">For reviewing database activity</span><br />
                  <span class="text-style-15">Audit Log Reports</span>
                </p>
              </div>
            </div>
            <div class="status-nd-timer">
              <i class="pi pi-times"></i>
            </div>
          </div>
          <p-divider></p-divider>
          <div class="notification-header mb-2">
            <h3 class="search-dd-title">Suggestions</h3>
          </div>
          <div class="notification-container mb-4">
            <div class="notification-media">
              <div class="search-round-box mr-2">
                <img width="24" height="24" src="assets/images/svg/gallery.svg" alt=""
                     class="search-user-avatar">
              </div>
              <div class="notification-content">
                <p class="notification-text">
                  <span class="text-style-15">Audit Log Reports</span><br />
                  <span class="text-style-13">For reviewing database activity</span>
                </p>
              </div>
            </div>
            <div class="status-nd-timer">
              <i class="pi pi-angle-right"></i>
            </div>
          </div>
          <div class="notification-container mb-4">
            <div class="notification-media">
              <div class="search-round-box mr-2">
                <img width="24" height="24" src="assets/images/svg/gallery.svg" alt=""
                     class="search-user-avatar">
              </div>
              <div class="notification-content">
                <p class="notification-text">
                  <span class="text-style-15">Audit Log Reports</span><br />
                  <span class="text-style-13">For reviewing database activity</span>
                </p>
              </div>
            </div>
            <div class="status-nd-timer">
              <i class="pi pi-angle-right"></i>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>

  <div class="item-6 chip-container" *ngIf="isInViewMode">
    <i class="pi pi-times chip-icon" (click)="closeViewMode()"></i>

    <p-button class="view-mode-button" label="You are in View Mode"></p-button>

  </div>

  <div class="item-6">

    <div class="right-block">
      <p-button class="rounded-button" (click)="openCreateAuditModal($event)" label="Create Audit" icon="pi pi-plus"></p-button>
      <p-overlayPanel #createAuditBtn [style]="{ width: 'auto' }">
        <ng-template pTemplate="content">
          <h4 class="text-style">Download the Desktop App</h4>
        </ng-template>
      </p-overlayPanel>
      <img class="img" src="assets/images/svg/tv.svg" (click)="createAuditBtn.toggle($event)" />
      <div class="overlap-group-wrapper">
        <div class="overlap-group">
          <img class="img-2" src="assets/images/svg/msg.svg" />
          <div class="frame"></div>
        </div>
      </div>
      <div class="overlap-group-wrapper" (click)="notification.toggle($event)">
        <div class="overlap-group">
          <img class="img-2" src="assets/images/svg/light.svg" />
          <div class="frame"></div>
          <p-overlayPanel #notification [style]="{ width: '25%' }">
            <ng-template pTemplate="content">
              <div class="notification">
                <div class="notification-header mb-2">
                  <h3 class="notification-title">Notification</h3>
                  <p-menu appendTo="notification-header" #nofificationMoreBtn [popup]="true"
                          [model]="items"></p-menu>
                  <span (click)="openMenu(nofificationMoreBtn, $event)"
                        class="pi pi-ellipsis-h p-2 circle-shape"></span>
                </div>
                <div class="notification-container">
                  <div class="notification-media">
                    <img width="50" height="50"
                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                         alt="" class="notification-user-avatar">

                    <div class="notification-like">
                      <img class="img-icon"
                           src="assets/images/svg/heart.svg" />
                    </div>

                  </div>
                  <div class="notification-content">
                    <p class="notification-text">
                      <span>
                        <strong class="pr-5">Domenica</strong> <span class="grey">
                          @domenica
                        </span>
                      </span><br>
                      <span class="grey mr-5"> Comment on</span> <strong>
                        Smiles – 3D
                        icons
                      </strong>
                    </p>
                  </div>
                  <div class="status-nd-timer">
                    <span class="notification-timer">1h</span>
                    <div class="notification-status"></div>
                  </div>
                </div>
                <p-divider></p-divider>
                <div class="notification-container">
                  <div class="notification-media">
                    <img width="50" height="50"
                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                         alt="" class="notification-user-avatar">

                    <div class="notification-like">
                      <img class="img-icon"
                           src="assets/images/svg/store.svg" />
                    </div>
                  </div>
                  <div class="notification-content">
                    <p class="notification-text">
                      <span>
                        <strong class="pr-5">Domenica</strong> <span class="grey">
                          @domenica
                        </span>
                      </span><br>
                      <span class="grey mr-5"> Comment on</span> <strong>
                        Smiles – 3D
                        icons
                      </strong>
                    </p>
                  </div>
                  <div class="status-nd-timer">
                    <span class="notification-timer">1h</span>
                    <div class="notification-status"></div>
                  </div>
                </div>
                <p-divider></p-divider>
                <div class="notification-container">
                  <div class="notification-media">
                    <img width="50" height="50"
                         src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
                         alt="" class="notification-user-avatar">
                    <div class="notification-like">
                      <img class="img-icon"
                           src="assets/images/svg/star.svg" />
                    </div>
                  </div>
                  <div class="notification-content">
                    <p class="notification-text">
                      <span>
                        <strong class="pr-5">Domenica</strong> <span class="grey">
                          @domenica
                        </span>
                      </span><br>
                      <span class="grey mr-5"> Comment on</span> <strong>
                        Smiles – 3D
                        icons
                      </strong>
                    </p>
                  </div>
                  <div class="status-nd-timer">
                    <span class="notification-timer">1h</span>
                    <div class="notification-status"></div>
                  </div>
                </div>
                <p-button class="b-radius-12" label="See all notifications"> </p-button>
              </div>

            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
      <div class="avatar" (click)="profile.toggle($event)">
        <img class="customer" src="assets/images/png/customer.png" />
        <div class="profile-popover">
          <p-overlayPanel #profile [style]="{ width: '17%' }">
            <ng-template pTemplate="content">
              <ul>
                <li>Profile </li>
                <li>Edit profile </li>
                <p-divider></p-divider>
                <li><i class="pi pi-money-bill mr-2"></i>Billing and payments </li>
                <li> <i class="pi pi-table mr-2"></i>Rate Limiting and Security </li>
                <p-divider></p-divider>
                <li class="active">
                  <img class="img-2 mr-2" src="assets/images/svg/graph.svg" />Upgrade to Pro
                </li>
                <p-divider></p-divider>
                <li>Account Setting </li>
                <li>Logout </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- create Audit modal  -->
<div *ngIf="isCreateAuditDialog">
  <app-create-audit-modal (dialogCloseEmitter)="dialogCloseEmitter($event)"></app-create-audit-modal>
</div>

<!-- Right Nav -->
<!-- portion saved view -->
<section class="side-saved">
  <p-sidebar [(visible)]="sidebarVisible" position="right">
    <ng-template pTemplate="header">
      <span class="header-title">All Saved Views ({{savedViewsCount}})</span>
    </ng-template>
    <ng-template pTemplate="content">
      <section *ngIf="hasSavedViews">
        <div class="box mb-3" *ngFor="let savedView of savedViews">
          <div class="rectangle">
            <div class="item">
              <div class="box-inner">
                <div class="group">
                  <div class="rectangle-group">
                    <div class="iconly-bold-show">
                      <div class="show"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text"
                   style="cursor:pointer;"
                   (click)="loadView(savedView)">
                <p class="heading">{{savedView.ViewName}}</p>
                <p class="date m-0">({{savedView.TableCount}}) Tables in view</p>
              </div>
            </div>
            <div class="item">
              <div class="box-circle">
                <div class="rectangle-circle">
                  <div>
                    <span class="vector-stroke pi pi-trash p-2 bg-white circle-shape action-buttons flex-common"
                          style="cursor:pointer;"
                          (click)="deleteSavedView(savedView)"
                          title="Delete {{savedView.ViewName}}">
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section *ngIf="!hasSavedViews">
        <div class="right-top">
          <img class="mb-4" src="assets/images/svg/undraw_feeling.svg" />

          <h2 class="grey-black">You have no saved View</h2>
          <span class="mb-4 grey-light">Click the button below to create and save a view</span>

          <button class="primary">Add a New View</button>
        </div>
      </section>

    </ng-template>
    <ng-template pTemplate="footer">
      <p-button *ngIf="hasSavedViews" class="add-new-view-button" label="Add New View"></p-button>
    </ng-template>
  </p-sidebar>
</section>
<!-- portion saved view end-->
