<div class="p-4" scroller>
  <!-- Title Bar -->
  <!-- <div class="d-flex flex-row align-items-center justify-content-between mb-5" style="height: 40px;">
    <div class="d-flex flex-row align-items-center justify-content-between w-100p" style="height: 90%;">
      <div class="d-flex align-items-center" style="height: 100%;">
        <div class="left-border"></div>
        <span class="title">Audits</span>
      </div>
      <div class="d-flex gap-3">
        <span class="p-input-icon-right" style="height: 100%;">
          <i class="pi pi-search"></i>
          <input type="text" class="search-input w-100" placeholder="Search Audits " pInputText />
        </span>
        <button class="table-button">Table</button>
      </div>

    </div>

  </div> -->
  <!-- Audit Profiles -->
  <div style="box-sizing: border-box;">
    <div class="row w-100 mb-2">
      <div class="col-4">
        <!--<p-checkbox (click)="headerCheckbox()" [(ngModel)]="headerCheckbox" [binary]="true" inputId="binary" class="header-checkbox"></p-checkbox>-->
        <span class="mx-4">Audit Name</span>
      </div>
      <div class="col-8 d-flex align-items-center justify-content-between">
        <span class="mx-3">Status</span>
        <span class="mx-3">Tables</span>
      </div>
    </div>

<ng-container *ngIf="!skeleton">
    <div class="content-card row mb-2" *ngFor="let auditProfile of auditProfiles">
      
      <div class="col-4 d-flex">

        <div class="img-card">
          <img src="assets/images/svg/sql.svg" alt="image">
        </div>
        <div class="content-text">
          <span class="content-title">{{auditProfile.DatabaseName}}</span>
          <span class="desc connection-string-short"
            title="{{auditProfile.ConnectionString}}">{{auditProfile.ConnectionString | slice:0:35}}...</span>
        </div>
      </div>
      <!-- Status -->
      <div class="status-section col">
        <div class="d-flex flex-column align-items-start justify-content-between"
          style="height: 100%;padding: 8px 0px;">
          <span class="status-text">{{auditProfile.AuditMetricsSummaryBrief}}</span>
          <div class="status-buttons">
            <span class="pi pi-sun p-2 bg-white circle-shape action-buttons"
              (click)="goToLighthouseDashboard(auditProfile)" title="Lighthouse Dashboard!"></span>

            <span class="pi pi-eye p-2 bg-white circle-shape action-buttons" (click)="viewAuditMetrics(auditProfile)"
              title="View Audit Metrics"></span>

            <span class="pi pi-pencil p-2 bg-white circle-shape action-buttons"
              (click)="OpenConnectionModal()" title="Update connection string">
            </span>

        <app-create-connection-modal
          [isSaveViewModalVisible]="isConnectionModalOpen"
          [connectionName]="currentConnectionString"
          (viewNameEnteredEventEmitter)="updateConnectionString($event,auditProfile)"></app-create-connection-modal>

            <span class="pi pi-trash p-2 bg-white circle-shape action-buttons flex-common"
              (click)="deleteAuditProfile(auditProfile)" title="Delete audit infrastructure">
            </span>

            <div class="toggle-menu">
              <p-menu appendTo="" styleClass="" #menu [popup]="true" [model]="toggleItems"></p-menu>
            </div>
            <!-- DO NOT DELETE!!! (Good example of how to use Menu) -->
            <!--<span (click)="openMenu(menu,$event)" class="pi pi-ellipsis-h p-2 bg-white circle-shape"></span>-->
          </div>
        </div>
        <div class="connect-section" style="padding: 8px 0px;">
          <span>{{auditProfile.AuditTablesCreated}}</span>
          <div class="bar"></div>
        </div>
      </div>
    </div>
  </ng-container>
 
 
  <ng-container *ngIf="skeleton">
   <div class="content-card row mb-2">
      
      <div class="col-4 d-flex">
        <div class="img-card">
          <app-skeleton [skeleton]="{shape:'circle', size:'4rem', class:'mr-2', count:[1]}"></app-skeleton>
       </div>
        <div class="content-text">
          <span class="content-title"><app-skeleton [skeleton]="{width:'300px', height:'1rem', count:[1]}"></app-skeleton></span>
          <span class="desc connection-string-short"><app-skeleton [skeleton]="{width: '200px', height:'1rem', class:'mt-1', count:[1]}"></app-skeleton></span>
        </div>
      </div>
      <div class="status-section col">
        <div class="d-flex flex-column align-items-start justify-content-between"
          style="height: 75%; margin-top: 1%;">
          <span class="status-text" style="padding: 0px;"><p-skeleton width="300px" height="1rem"></p-skeleton></span>
          <div class="status-buttons">
            <app-skeleton [skeleton]="{shape:'circle', size:'1.5rem', class:'ml-6', count:[1]}"></app-skeleton>
            <app-skeleton [skeleton]="{shape:'circle', size:'1.5rem', count:[1]}"></app-skeleton>            
            <app-skeleton [skeleton]="{shape:'circle', size:'1.5rem', count:[1]}"></app-skeleton>            
            <app-skeleton [skeleton]="{shape:'circle', size:'1.5rem', count:[1]}"></app-skeleton>            
          
            <div class="toggle-menu">
              <p-menu appendTo="" styleClass="" #menu [popup]="true" [model]="toggleItems"></p-menu>
            </div>
          </div>
        </div>
        <div class="connect-section" style="padding: 8px 0px;">
          <span style="margin-right: 5px;"><p-skeleton shape="circle" size="1rem"></p-skeleton></span>
          <app-skeleton [skeleton]="{width:'30px', height:'1rem', count:[1]}"></app-skeleton>
        </div>
      </div>
    </div>
</ng-container>
   
  </div>
</div>

<div *ngIf="isModalopen">
  <app-create-audit-modal [auditmetricsDetails]="auditMetricsData"
    (dialogCloseEmitter)="dialogCloseEmitter($event)"></app-create-audit-modal>
</div>

<div *ngIf="isViewAuditLog">
  <app-audit-log-modal [auditmetricsDetails]="auditMetricsData"
    (dialogCloseEmitter)="auditMetricsDialogCloseEmitter($event)"></app-audit-log-modal>
</div>
