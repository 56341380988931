<section id="sidebar" [ngClass]="toggle? 'width-100': ''">
    <p-button [icon]="toggle ? 'pi pi-angle-double-right': 'pi pi-angle-double-left'" class="p-button-rounded toggle-btn" (onClick)="toggle=!toggle"></p-button>
    <div *ngIf="!toggle">
        <a href="/dashboard" class="brand">
            <img src="../../assets/images/png/logo.png" class="logoImg">
        </a>
        <ul class="side-menu top">
            <div class="main-list-content">
                <div class="inner-content">
                    <li>
                        <div class="dropDownMenu flex justify-content-center">
                            <p-panelMenu [model]="items" [multiple]="false"></p-panelMenu>
                        </div>
                    </li>
                </div>
                <div>
                    <li>
                        <p-divider></p-divider>
                        <div class="f-card">
                            <div class="card-content">
                                <span class="f-icon">
                                    <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="UI icon/database/light">
                                            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M21.3608 7.99593C21.3056 7.87505 21.0359 7.37671 19.724 6.77699C18.2471 6.10186 16.0488 5.625 13.5 5.625C10.9512 5.625 8.75287 6.10186 7.276 6.77699C5.96413 7.37671 5.69444 7.87505 5.63917 7.99593C5.69752 8.11275 5.9719 8.55011 7.21544 9.07496C8.69748 9.70047 10.9132 10.125 13.5 10.125C16.0868 10.125 18.3025 9.70047 19.7846 9.07496C21.0281 8.55012 21.3025 8.11276 21.3608 7.99593ZM13.5 12.375C16.6807 12.375 19.5188 11.7877 21.375 10.8109V13.4768C21.375 13.4768 21.3733 13.4795 21.3711 13.4851C21.3434 13.5542 21.1452 14.0482 19.7827 14.6509C18.3201 15.2978 16.1164 15.75 13.5 15.75C10.8836 15.75 8.67995 15.2978 7.21734 14.6509C5.85473 14.0482 5.65683 13.5539 5.62914 13.4847C5.62691 13.4791 5.62524 13.4764 5.62524 13.4764L5.62505 10.8109C7.48125 11.7877 10.3194 12.375 13.5 12.375ZM23.625 13.5V8.00357V7.875H23.6212C23.4724 5.37807 18.998 3.375 13.5 3.375C8.00217 3.375 3.52779 5.37816 3.37883 7.875H3.375V8.00357V13.5V19.125C3.375 21.6813 7.81935 23.625 13.5 23.625C19.1807 23.625 23.625 21.6813 23.625 19.125V13.5ZM21.375 16.3738C19.5335 17.3775 16.7147 18 13.5 18C10.2853 18 7.46651 17.3775 5.62504 16.3738L5.62524 19.1014C5.62524 19.1014 5.62691 19.1041 5.62914 19.1097C5.65683 19.1789 5.85473 19.6732 7.21734 20.2759C8.67995 20.9228 10.8836 21.375 13.5 21.375C16.1164 21.375 18.3201 20.9228 19.7827 20.2759C21.1452 19.6732 21.3434 19.1792 21.3711 19.1101C21.3733 19.1045 21.375 19.1018 21.375 19.1018V16.3738Z" fill="black" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="text">Documentation</span>
                            </div>
                            <div class="f-card-footer">
                                <span>19</span>
                            </div>
                        </div>
                        <p-divider></p-divider>
                        <div class="f-card">
                            <div class="card-content">
                                <span class="f-icon">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="UI icon/help/light">
                                            <path id="Ellipse 134 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#6F767E" />
                                            <circle id="Ellipse 190" cx="12" cy="18" r="1" fill="#6F767E" />
                                            <path id="Ellipse 191 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M12 8C11.1307 8 10.3886 8.5551 10.1135 9.33325C9.92948 9.85396 9.35815 10.1269 8.83744 9.94284C8.31672 9.75879 8.0438 9.18747 8.22784 8.66675C8.77648 7.11451 10.2568 6 12 6C14.2091 6 16 7.79086 16 10C16 11.8638 14.7252 13.4299 13 13.874V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V13C11 12.4477 11.4477 12 12 12C13.1045 12 14 11.1046 14 10C14 8.89543 13.1045 8 12 8Z" fill="#6F767E" />
                                        </g>
                                    </svg>
                                </span>
                                <span class="text">Help & getting started</span>
                            </div>
                            <div class="f-card-footer">
                                <span class="footer-icon">11</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="theme-switcher">
                            <button class="btn-active" (click)="Check('light')">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="UI icon/sun/filled">
                                        <path id="Oval" fill-rule="evenodd" clip-rule="evenodd" d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="#1A1D1F" />
                                        <g id="Vector">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9951 1.5C12.5474 1.5 12.9951 1.94772 12.9951 2.5V3.5C12.9951 4.05228 12.5474 4.5 11.9951 4.5C11.4428 4.5 10.9951 4.05228 10.9951 3.5V2.5C10.9951 1.94772 11.4428 1.5 11.9951 1.5Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9951 19.5C12.5474 19.5 12.9951 19.9477 12.9951 20.5V21.4968C12.9951 22.0491 12.5474 22.4968 11.9951 22.4968C11.4428 22.4968 10.9951 22.0491 10.9951 21.4968V20.5C10.9951 19.9477 11.4428 19.5 11.9951 19.5Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.4967 11.9984C1.4967 11.4461 1.94442 10.9984 2.4967 10.9984H3.4967C4.04899 10.9984 4.4967 11.4461 4.4967 11.9984C4.4967 12.5507 4.04899 12.9984 3.4967 12.9984H2.4967C1.94442 12.9984 1.4967 12.5507 1.4967 11.9984Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4967 11.9984C19.4967 11.4461 19.9444 10.9984 20.4967 10.9984H21.4935C22.0458 10.9984 22.4935 11.4461 22.4935 11.9984C22.4935 12.5507 22.0458 12.9984 21.4935 12.9984H20.4967C19.9444 12.9984 19.4967 12.5507 19.4967 11.9984Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57171 19.4219C4.18118 19.0313 4.18118 18.3982 4.57171 18.0077L5.27881 17.3005C5.66934 16.91 6.3025 16.91 6.69303 17.3005C7.08355 17.6911 7.08355 18.3242 6.69303 18.7148L5.98592 19.4219C5.5954 19.8124 4.96223 19.8124 4.57171 19.4219Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2996 6.69394C16.9091 6.30342 16.9091 5.67025 17.2996 5.27973L18.0045 4.5749C18.395 4.18438 19.0281 4.18438 19.4187 4.5749C19.8092 4.96543 19.8092 5.59859 19.4187 5.98911L18.7138 6.69394C18.3233 7.08447 17.6902 7.08447 17.2996 6.69394Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57171 4.5749C4.96223 4.18438 5.5954 4.18438 5.98592 4.5749L6.69303 5.28201C7.08355 5.67253 7.08355 6.3057 6.69303 6.69622C6.3025 7.08675 5.66934 7.08675 5.27881 6.69622L4.57171 5.98911C4.18118 5.59859 4.18118 4.96543 4.57171 4.5749Z" fill="#1A1D1F" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2996 17.3028C17.6902 16.9123 18.3233 16.9123 18.7138 17.3028L19.4187 18.0077C19.8092 18.3982 19.8092 19.0313 19.4187 19.4219C19.0281 19.8124 18.395 19.8124 18.0045 19.4219L17.2996 18.717C16.9091 18.3265 16.9091 17.6933 17.2996 17.3028Z" fill="#1A1D1F" />
                                        </g>
                                    </g>
                                </svg>
                                <span>light</span>
                            </button>
                            <button (click)="Check('dark')">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="UI icon/moon/light">
                                        <path id="Subtract (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M8.56567 6.02548C6.71318 7.28659 5.5 9.41147 5.5 11.8165C5.5 15.6825 8.63401 18.8165 12.5 18.8165C14.9051 18.8165 17.0299 17.6034 18.2911 15.7509C13.1817 15.1923 9.12421 11.1348 8.56567 6.02548ZM19.5 13.8166C20.366 13.8166 21.1118 14.5798 20.7691 15.3751C19.3898 18.5759 16.2066 20.8165 12.5 20.8165C7.52944 20.8165 3.5 16.7871 3.5 11.8165C3.5 8.10997 5.74067 4.92673 8.9414 3.54746C9.73674 3.20472 10.5 3.9505 10.5 4.81655C10.5 9.78712 14.5294 13.8166 19.5 13.8166Z" fill="#6F767E" />
                                    </g>
                                </svg>
                                <span>Dark</span>
                            </button>
                        </div>
                    </li>
                </div>
            </div>
        </ul>
    </div>
    <div *ngIf="toggle" class="mob-menu-toggle">
        <a href="/dashboard" class="brand">
            <img src="../../assets/images/svg/mobile-logo.svg" class="mob-logo-Img">
        </a>
        <ul class="side-menu top">
            <div class="main-list-content">
                <div class="inner-content">
                
                    <!-- Old Navbar code without hover submenu card "We let it stay for a few days, maybe longer than needed"-->
                    <!-- <li>
                        <div (click)="checkSelected('pi-home','/dashboard')" class="f-card" [ngClass]="selected === 'pi-home' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon ">
                                    <i class="pi pi-fw pi-home"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="checkSelected('pi-database','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'pi-database' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <i class="pi pi-fw pi-database"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="checkSelected('pi-shield','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'pi-shield' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <i class="pi pi-fw pi-shield"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="checkSelected('pi-users','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'pi-users' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <i class="pi pi-fw pi-users"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="checkSelected('pi-server','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'pi-server' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <i class="pi pi-fw pi-server"></i>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div (click)="checkSelected('pi-tag','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'pi-tag' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <i class="pi pi-fw pi-tag"></i>
                                </span>
                            </div>
                        </div>
                    </li> -->
            <!-- New Navbar code with hover submenu card -->
            <li *ngFor="let item of navItems; let i = index">
                <!-- temp... rout set '{{item.routerLink}}' -->
                <div (click)="checkSelected(item.icon, item.routerLink)" 
                    (mouseover)="showSubMenu(item, i)" (mouseleave)="hideSubMenu()" 
                    class="f-card" [ngClass]="selected === item.icon ? 'active' : ''">
                    <div class="card-content">
                        <span class="f-icon">
                            <i class="pi pi-fw {{item.icon}}"></i>
                        </span>
                    </div>
                    <div class="menuCard {{item.top}}" *ngIf="selectedItem?.index == i && selectedItem && selectedItem.submenu">
                        <ul>
                            <li *ngFor="let subItem of selectedItem.submenu" (click)="subSelected(subItem.label, subItem.routerLink)">
                                <span class="f-icon" >
                                    <span  [ngClass]="selectedSub === subItem.label ? 'active-sub' : ''">{{subItem.label}}</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>            
                </div>
                <div>
                    <li>
                        <p-divider></p-divider>
                        <!-- temp... rout set '/lighthouse-data-explorer'  -->
                        <div (click)="checkSelected('black','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'black' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <svg width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="UI icon/database/light">
                                            <path id="Union" fill-rule="evenodd" clip-rule="evenodd" d="M21.3608 7.99593C21.3056 7.87505 21.0359 7.37671 19.724 6.77699C18.2471 6.10186 16.0488 5.625 13.5 5.625C10.9512 5.625 8.75287 6.10186 7.276 6.77699C5.96413 7.37671 5.69444 7.87505 5.63917 7.99593C5.69752 8.11275 5.9719 8.55011 7.21544 9.07496C8.69748 9.70047 10.9132 10.125 13.5 10.125C16.0868 10.125 18.3025 9.70047 19.7846 9.07496C21.0281 8.55012 21.3025 8.11276 21.3608 7.99593ZM13.5 12.375C16.6807 12.375 19.5188 11.7877 21.375 10.8109V13.4768C21.375 13.4768 21.3733 13.4795 21.3711 13.4851C21.3434 13.5542 21.1452 14.0482 19.7827 14.6509C18.3201 15.2978 16.1164 15.75 13.5 15.75C10.8836 15.75 8.67995 15.2978 7.21734 14.6509C5.85473 14.0482 5.65683 13.5539 5.62914 13.4847C5.62691 13.4791 5.62524 13.4764 5.62524 13.4764L5.62505 10.8109C7.48125 11.7877 10.3194 12.375 13.5 12.375ZM23.625 13.5V8.00357V7.875H23.6212C23.4724 5.37807 18.998 3.375 13.5 3.375C8.00217 3.375 3.52779 5.37816 3.37883 7.875H3.375V8.00357V13.5V19.125C3.375 21.6813 7.81935 23.625 13.5 23.625C19.1807 23.625 23.625 21.6813 23.625 19.125V13.5ZM21.375 16.3738C19.5335 17.3775 16.7147 18 13.5 18C10.2853 18 7.46651 17.3775 5.62504 16.3738L5.62524 19.1014C5.62524 19.1014 5.62691 19.1041 5.62914 19.1097C5.65683 19.1789 5.85473 19.6732 7.21734 20.2759C8.67995 20.9228 10.8836 21.375 13.5 21.375C16.1164 21.375 18.3201 20.9228 19.7827 20.2759C21.1452 19.6732 21.3434 19.1792 21.3711 19.1101C21.3733 19.1045 21.375 19.1018 21.375 19.1018V16.3738Z" fill="black" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <p-divider></p-divider>
                        <!-- temp... rout set '/lighthouse-data-explorer'  -->
                        <div (click)="checkSelected('#6F767E','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === '#6F767E' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="UI icon/help/light">
                                            <path id="Ellipse 134 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#6F767E" />
                                            <circle id="Ellipse 190" cx="12" cy="18" r="1" fill="#6F767E" />
                                            <path id="Ellipse 191 (Stroke)" fill-rule="evenodd" clip-rule="evenodd" d="M12 8C11.1307 8 10.3886 8.5551 10.1135 9.33325C9.92948 9.85396 9.35815 10.1269 8.83744 9.94284C8.31672 9.75879 8.0438 9.18747 8.22784 8.66675C8.77648 7.11451 10.2568 6 12 6C14.2091 6 16 7.79086 16 10C16 11.8638 14.7252 13.4299 13 13.874V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V13C11 12.4477 11.4477 12 12 12C13.1045 12 14 11.1046 14 10C14 8.89543 13.1045 8 12 8Z" fill="#6F767E" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- temp... rout set '/lighthouse-data-explorer'  -->
                        <div (click)="checkSelected('none','/lighthouse-data-explorer')" class="f-card" [ngClass]="selected === 'none' ? 'active' : ''">
                            <div class="card-content">
                                <span class="f-icon mt-2">
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g id="UI icon/sun/filled">
                                            <path id="Oval" fill-rule="evenodd" clip-rule="evenodd" d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" fill="#1A1D1F" />
                                            <g id="Vector">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9951 1.5C12.5474 1.5 12.9951 1.94772 12.9951 2.5V3.5C12.9951 4.05228 12.5474 4.5 11.9951 4.5C11.4428 4.5 10.9951 4.05228 10.9951 3.5V2.5C10.9951 1.94772 11.4428 1.5 11.9951 1.5Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9951 19.5C12.5474 19.5 12.9951 19.9477 12.9951 20.5V21.4968C12.9951 22.0491 12.5474 22.4968 11.9951 22.4968C11.4428 22.4968 10.9951 22.0491 10.9951 21.4968V20.5C10.9951 19.9477 11.4428 19.5 11.9951 19.5Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.4967 11.9984C1.4967 11.4461 1.94442 10.9984 2.4967 10.9984H3.4967C4.04899 10.9984 4.4967 11.4461 4.4967 11.9984C4.4967 12.5507 4.04899 12.9984 3.4967 12.9984H2.4967C1.94442 12.9984 1.4967 12.5507 1.4967 11.9984Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4967 11.9984C19.4967 11.4461 19.9444 10.9984 20.4967 10.9984H21.4935C22.0458 10.9984 22.4935 11.4461 22.4935 11.9984C22.4935 12.5507 22.0458 12.9984 21.4935 12.9984H20.4967C19.9444 12.9984 19.4967 12.5507 19.4967 11.9984Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57171 19.4219C4.18118 19.0313 4.18118 18.3982 4.57171 18.0077L5.27881 17.3005C5.66934 16.91 6.3025 16.91 6.69303 17.3005C7.08355 17.6911 7.08355 18.3242 6.69303 18.7148L5.98592 19.4219C5.5954 19.8124 4.96223 19.8124 4.57171 19.4219Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2996 6.69394C16.9091 6.30342 16.9091 5.67025 17.2996 5.27973L18.0045 4.5749C18.395 4.18438 19.0281 4.18438 19.4187 4.5749C19.8092 4.96543 19.8092 5.59859 19.4187 5.98911L18.7138 6.69394C18.3233 7.08447 17.6902 7.08447 17.2996 6.69394Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.57171 4.5749C4.96223 4.18438 5.5954 4.18438 5.98592 4.5749L6.69303 5.28201C7.08355 5.67253 7.08355 6.3057 6.69303 6.69622C6.3025 7.08675 5.66934 7.08675 5.27881 6.69622L4.57171 5.98911C4.18118 5.59859 4.18118 4.96543 4.57171 4.5749Z" fill="#1A1D1F" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2996 17.3028C17.6902 16.9123 18.3233 16.9123 18.7138 17.3028L19.4187 18.0077C19.8092 18.3982 19.8092 19.0313 19.4187 19.4219C19.0281 19.8124 18.395 19.8124 18.0045 19.4219L17.2996 18.717C16.9091 18.3265 16.9091 17.6933 17.2996 17.3028Z" fill="#1A1D1F" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        </ul>
    </div>
</section>
