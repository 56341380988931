<div class="save-view-modal">
    <p-dialog header="Name your View" [(visible)]="isSaveViewModalVisible" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false">
        <div class="modal-body">
            <div class="eye-container">
               <div class="eye-content">
                    <img width="42px" height="34px" src="assets/images/svg/eye-bold.svg" alt="">
               </div> 
            </div>
            <div class="input-box">
                <input class="text" pInputText pKeyFilter="int" [(ngModel)]="viewName" placeholder="My View Name " />
            </div>
            <div class="btn-comntainer1">
                <p-button class="save-btn" label="Save" (click)="saveView()"></p-button> 
            </div>
        </div>
    </p-dialog>
</div>
