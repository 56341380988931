import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// custom
import { AuthInterceptor } from './core/AuthInterceptor';
import { BaseComponent } from './pages/base/base.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { LighthouseDataExplorerComponent } from './pages/features/_lighthouse/lighthouse-data-explorer/lighthouse-data-explorer.component';
import { LighthouseDashboardComponent } from './pages/features/_lighthouse/lighthouse-dashboard/lighthouse-dashboard.component';
import { RealTimeNotificationsComponent } from './pages/features/real-time-notifications/real-time-notifications.component';
//import { CreateAuditComponent } from './pages/features/create-audit/create-audit.component';
import { AnomalyDetectionComponent } from './pages/features/anomaly-detection/anomaly-detection.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { SideNaveComponent } from './components/layout/side-nav/side-nav.component';
// primeng
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChartModule } from 'primeng/chart';
import { FieldsetModule } from 'primeng/fieldset';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { DashboardOverviewComponent } from './pages/dashboard/components/overview/overview.component';
import { GraphComponent } from './components/dashboard/graph/graph.component';
import { AuditComponent } from './pages/dashboard/components/audit/audit.component';
import { AuditActivityOverviewComponent } from './pages/features/_lighthouse/lighthouse-dashboard/components/audit-activity-overview/audit-activity-overview.component';
import { FilterModalComponent } from './pages/features/_lighthouse/lighthouse-dashboard/modals/filter-modal/filter-modal.component';
import { OrderModalComponent } from './pages/features/_lighthouse/lighthouse-dashboard/modals/order-modal/order-modal.component';
import { ProductModalComponent } from './pages/features/_lighthouse/lighthouse-dashboard/modals/product-modal/product-modal.component';
import { UserModalComponent } from './pages/features/_lighthouse/lighthouse-dashboard/modals/user-modal/user-modal.component';
import { DataTableViewModalComponent } from './pages/features/_lighthouse/lighthouse-data-explorer/modals/data-table-view-modal/data-table-view-modal.component';
import { CreateAuditModalComponent } from './components/create-audit-modal/create-audit-modal.component';
import { PreviewDataModalComponent } from './pages/features/_lighthouse/lighthouse-dashboard/modals/preview-data-modal/preview-data-modal.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { SaveViewModalComponent } from './common/modal/save-view-modal/save-view-modal.component'; //module for saved view
import { AuditLogModalComponent } from './pages/dashboard/components/audit-log-modal/audit-log-modal.component';
import { TooltipModule } from 'primeng/tooltip';
import { CreateConnectionModalComponent } from './common/modal/create-connection-modal/create-connection-modal.component';
import { SkeletonComponent } from './common/skeleton/skeleton.component';
import { SkeletonModule } from "primeng/skeleton";

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    DashboardComponent,
    AnomalyDetectionComponent,
    RealTimeNotificationsComponent,
    LighthouseDashboardComponent,
    LighthouseDataExplorerComponent,
    HomeComponent,
    HeaderComponent,
    SideNaveComponent,
    DashboardOverviewComponent,
    GraphComponent,
    AuditComponent,
    AuditActivityOverviewComponent,
    FilterModalComponent,
    OrderModalComponent,
    ProductModalComponent,
    UserModalComponent,
    PreviewDataModalComponent,
    DataTableViewModalComponent,
    CreateAuditModalComponent,
    PreviewDataModalComponent,
    SaveViewModalComponent,
    AuditLogModalComponent,
    CreateConnectionModalComponent,
    SkeletonComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DividerModule,
    PanelMenuModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    DropdownModule,
    OverlayPanelModule,
    MenuModule,
    ButtonModule,
    SelectButtonModule,
    SplitButtonModule,
    ToggleButtonModule,
    PanelModule,
    FormsModule,
    CheckboxModule,
    ChartModule,
    FieldsetModule,
    DialogModule,
    TableModule,
    CalendarModule,
    ProgressSpinnerModule,
    ProgressBarModule, //ProgressBar added in create audit modal
    SidebarModule, //module for saved view
    TooltipModule,
    SkeletonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
