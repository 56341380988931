<div class="createAudit">
  <p-dialog header="Header" (onHide)="dialogClose()" [modal]="true" [style]="{width: '35.5rem'}" [(visible)]="isViewAuditLog">
    <ng-template pTemplate="header">
      <div class="d-flex align-items-center" [style]="{paddingBottom: '0px'}">
        <div class="left-border left-border-bg"></div>
        <div class="d-flex-d-column margin-left-5">
          <span class="user-title-text">Audit Log</span>
        </div>
      </div>
    </ng-template>
    <p-divider></p-divider>
    <div class="progress-container">
      <!-- Audit Log isViewAuditLog -->
      <div class="success-databases-msg-box">
        <div class="success-database-name input-box mb-1">
          <label class="label-bold" for="">Audit Name: </label>
          <p class="regular-label m-0"> {{auditmetricsDetails?.DatabaseName}}</p>
        </div>
        <div class="success-database-name input-box mb-3">
          <label class="label-bold" for="">Server: </label>
          <p class="regular-label m-0"> {{auditmetricsDetails?.ConnectionString}}</p>
        </div>
        <div class="success-database-name input-box mb-1">
          <label class="label-bold" for="">Audit Summary: </label>
        </div>
        <div class="success-database-name input-box mb-1">
          <p class="regular-label m-0">- Database Operations:</p>
        </div>
        <div class="px-2">
          <div class="d-flex gap-2 database-msgs mb-1 flex-column">
            <p class="regular-label m-0" *ngFor="let data of auditmetricsDetails?.AuditMetrics"> - {{data}}</p>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
</div>